export const tableTags = [
  "table",
  "tr",
  "th",
  "tf",
  "thead",
  "tbody",
  "tfoot",
  "td",
];
