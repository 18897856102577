import LogoFull from "@/wab/client/components/LogoFull";
import { PageFooter } from "@/wab/client/components/pages/PageFooter";
import * as React from "react";
import { ReactNode } from "react";

export function IntakeFlowForm(props: { children: ReactNode }) {
  return (
    <div className={"LoginForm__Container"}>
      <div className={"LoginForm__Content"}>
        <div className={"LoginForm__Logo"} style={{ width: "auto" }}>
          <LogoFull width={250} height={64} />
        </div>
        {props.children}
        <PageFooter />
      </div>
    </div>
  );
}
