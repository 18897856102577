import { U } from "@/wab/client/cli-routes";
import { useAppCtx } from "@/wab/client/contexts/AppContexts";
import { DefaultNewProjectModalProps } from "@/wab/client/plasmic/plasmic_kit_dashboard/PlasmicNewProjectModal";
import { WorkspaceId } from "@/wab/shared/ApiSchema";
import { observer } from "mobx-react";
import * as React from "react";
import { BareModal } from "./studio/BareModal";
import sty from "./NewProjectStartModal.module.scss";
import classNames from "classnames";

interface NewProjectStartModalProps extends DefaultNewProjectModalProps {
  onForm: () => void;
  onTemplate: () => void;
  onCancel: () => void;
  workspaceId?: WorkspaceId;
}

const NewProjectStartModal = observer(function NewProjectStartModal({
  workspaceId,
  onForm,
  onTemplate,
  onCancel,
}: NewProjectStartModalProps) {
  const appCtx = useAppCtx();

  const [isCreating, setCreating] = React.useState(false);

  const createEmptyProject = async () => {
    setCreating(true);

    try {
      const { project } = await appCtx.app.withSpinner(
        appCtx.api.createSite(workspaceId)
      );

      location.href = U.project({ projectId: project.id });
    } catch (e) {
      setCreating(false);
    }
  };

  return (
    <BareModal onClose={onCancel} width={1450} style={{ top: 32 }}>
      <div className={sty.modal}>
        <NewProjectCard
          title="Smart Generator"
          description="Save precious time and instantly generate a project tailored to your client’s goals based on your answers to a quick survey."
          img="static/img/frame-61.png"
          newLabel={true}
          disabled={isCreating}
          onClick={onForm}
        />
        <NewProjectCard
          title="Template Library"
          description="Choose from a range of inspirational and responsive website templates to create exceptional sites."
          img="static/img/frame-62.png"
          disabled={isCreating}
          onClick={onTemplate}
        />
        <NewProjectCard
          title="Start From Scratch"
          description="You’d like something very unique ? Start from scratch with our UI Builder, and integrate all the custom code you need."
          img="static/img/frame-63.png"
          disabled={isCreating}
          onClick={createEmptyProject}
        />
      </div>
    </BareModal>
  );
});

function NewProjectCard({
  title,
  description,
  img,
  newLabel,
  disabled,
  onClick,
}: {
  title: string;
  description: string;
  img: string;
  newLabel?: boolean;
  disabled?: boolean;
  onClick: () => void;
}) {
  return (
    <div
      className={classNames([sty.card, disabled && sty.cardDisabled])}
      onClick={disabled ? undefined : onClick}
    >
      <div className={sty.cardTop}>
        <div className={sty.titleBox}>
          <p className={sty.title}>{title}</p>

          {newLabel && <p className={sty.new}>New</p>}
        </div>
        <p>{description}</p>
      </div>
      <div className={sty.cardBottom}>
        <img src={img} alt="" />
      </div>
    </div>
  );
}

export default NewProjectStartModal;
