import {
  TemplateFormGroups,
  TemplateFormStep,
  templateFormGroups,
} from "./templateForm";
import { TemplateFormResolver } from "./templateFormResolver";

export const templateFormConfig = (
  v: TemplateFormResolver,
  config: {
    [key: string]: {
      jira?: string;
      plasmic: string;
    };
  }
) => {
  return {
    groups: templateFormGroups,
    steps: {
      1: {
        states: [
          v.buildState("BusinessType", {
            self: true,
            unique: true,
            required: true,
            children: {
              Association: false,
            },
          }),
        ],
      },
      2: {
        states: [
          v.buildState("MainGoals", {
            self: true,
            required: true,
          }),
        ],
      },
      3: {
        skip:
          !v.isEqual("BusinessType", "Ecommerce") &&
          !v.contains("MainGoals", "Sell"),
        states: [
          v.buildState("ProductType", {
            self: true,
            required: true,
          }),
        ],
      },
      4: {
        states: [
          v.buildState("OnlineSales", {
            self:
              v.isEqual("BusinessType", "Ecommerce") ||
              v.contains("MainGoals", "Sell"),
            children: {
              Course: v.contains("ProductType", "Course"),
              Delivery: v.contains("BusinessType", "Food"),
            },
          }),
          v.buildState("Leads", {
            self: v.contains("MainGoals", "Leads"),
          }),
          v.buildState("Showcase", {
            self:
              v.contains("MainGoals", "Awarness") ||
              v.contains("MainGoals", "Showcase"),
          }),
          v.buildState("News", {
            self: v.contains("MainGoals", "News"),
          }),
          v.buildState("Traffic", {
            self: v.contains("MainGoals", "Traffic"),
            children: {
              Management: v.contains("BusinessType", "Food"),
            },
          }),
          v.buildState("Community", {
            self: v.contains("MainGoals", "Community"),
            children: {
              Social: !v.contains("News", "Social"),
            },
          }),
          v.buildState("Support", {
            self: v.contains("MainGoals", "Support"),
            children: {
              Forum: !v.contains("Community", "Advantage"),
            },
          }),
          v.buildState("AdditionalRequirement", {
            self: true,
          }),
        ],
      },
    },
    templates: [
      {
        condition:
          v.isEqual("BusinessType", "Ecommerce") &&
          v.contains("ProductType", "Physical") &&
          !v.isEmpty("Community"),
        ...config.temp7,
      },
      {
        condition:
          v.isEqual("BusinessType", "Ecommerce") &&
          v.contains("ProductType", "Physical"),
        ...config.temp22,
      },
      {
        condition:
          v.isEqual("BusinessType", "Ecommerce") &&
          v.contains("ProductType", "Digital") &&
          !v.contains("ProductType", "Course"),
        ...config.temp24,
      },
      {
        condition:
          v.isEqual("BusinessType", "Ecommerce") &&
          v.contains("ProductType", "Course"),
        ...config.temp17,
      },
      {
        condition: v.isEqual("BusinessType", "Food") && v.isEmpty("Traffic"),
        ...config.temp5,
      },
      {
        condition:
          v.isEqual("BusinessType", "Food") && v.contains("MainGoals", "Sell"),
        ...config.temp9,
      },
      {
        condition: v.isEqual("BusinessType", "Food"),
        ...config.temp27,
      },
      {
        condition: v.isEqual("BusinessType", "Hospitality"),
        ...config.temp15,
      },
      {
        condition: v.isEqual("BusinessType", "Agency"),
        ...config.temp13,
      },
      {
        condition: v.isEqual("BusinessType", "Technology"),
        ...config.temp23,
      },
      {
        condition: v.isEqual("BusinessType", "Education"),
        ...config.temp17,
      },
      {
        condition: v.isEqual("BusinessType", "Healthcare"),
        ...config.temp16,
      },
      {
        condition: v.isEqual("BusinessType", "RealEstate"),
        ...config.temp1,
      },
      {
        condition: v.isEqual("BusinessType", "Business"),
        ...config.temp25,
      },
      {
        condition: true,
        ...config.default,
      },
    ],
  } satisfies {
    groups: TemplateFormGroups;
    steps: {
      [K in TemplateFormStep]: {
        skip?: boolean;
        states?: ReturnType<typeof v.buildState>[];
      };
    };
    templates: {
      jira?: string;
      condition: boolean;
      plasmic: string;
    }[];
  };
};
