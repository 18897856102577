// This is a skeleton starter React component generated by Plasmic.
// Feel free to edit as you see fit.
import {
  PlasmicProjectListSection,
  PlasmicProjectListSection__VariantsArgs,
} from "@/wab/client/plasmic/plasmic_kit/PlasmicProjectListSection";
import React, { ReactNode, useState } from "react";

interface ProjectListSectionProps {
  children?: ReactNode;
  name?: ReactNode;
  icon?: ReactNode;
  type?: PlasmicProjectListSection__VariantsArgs["type"];
  // className prop is required for positioning instances of
  // this Component
  className?: string;
  defaultCollapsed?: boolean;

  onSetCollapsed?: (collapsed: boolean) => void;
}

function ProjectListSection(props: ProjectListSectionProps) {
  const { defaultCollapsed = false } = props;
  const [collapsed, setCollapsed] = useState(defaultCollapsed);
  function toggleCollapsed() {
    setCollapsed(!collapsed);
    props.onSetCollapsed?.(!collapsed);
  }

  return (
    <PlasmicProjectListSection
      variants={{
        states: collapsed ? "collapsed" : undefined,
        type: props.type,
      }}
      args={{
        container: props.children,
        name: props.name,
        icon: props.icon,
      }}
      root={
        // className prop needs to be piped to the root element of this component
        {
          className: props.className,
        }
      }
      header={{
        onClick: toggleCollapsed,
      }}
    />
  );
}

export default ProjectListSection as React.FunctionComponent<ProjectListSectionProps>;
