// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import {
  DefaultDropdownOverlayProps,
  PlasmicDropdownOverlay,
} from "@/wab/client/plasmic/plasmic_kit_design_system/PlasmicDropdownOverlay";
import * as React from "react";

interface DropdownOverlayProps extends DefaultDropdownOverlayProps {
  style?: React.CSSProperties;
}

const DropdownOverlay = React.forwardRef(function DropdownOverlay(
  props: DropdownOverlayProps,
  ref: React.Ref<HTMLDivElement>
) {
  return (
    <PlasmicDropdownOverlay
      root={{ ref, className: "dropdown-overlay overflow-scroll-y" }}
      {...props}
    />
  );
});

export default DropdownOverlay;
