/** @format */

import { U, UU } from "@/wab/client/cli-routes";
import { promptNewTeam } from "@/wab/client/components/dashboard/dashboard-actions";
import sty from "@/wab/client/components/dashboard/DefaultLayout.module.css";
import NavButton from "@/wab/client/components/dashboard/NavButton";
import NavSeparator from "@/wab/client/components/dashboard/NavSeparator";
import { recentlyEndedTrial } from "@/wab/client/components/FreeTrial";
import Logo from "@/wab/client/components/Logo";
import LogoFull from "@/wab/client/components/LogoFull";
import NewProjectModals from "@/wab/client/components/NewProjectModals";
import { PublicLink } from "@/wab/client/components/PublicLink";
import { Avatar } from "@/wab/client/components/studio/Avatar";
import Button from "@/wab/client/components/widgets/Button";
import { useAppCtx } from "@/wab/client/contexts/AppContexts";
import TriangleBottomIcon from "@/wab/client/plasmic/plasmic_kit/PlasmicIcon__TriangleBottom";
import {
  DefaultDefaultLayoutProps,
  PlasmicDefaultLayout,
  PlasmicDefaultLayout__OverridesType,
} from "@/wab/client/plasmic/plasmic_kit_dashboard/PlasmicDefaultLayout";
import UnorderedListsvgIcon from "@/wab/client/plasmic/q_4_icons/icons/PlasmicIcon__UnorderedListsvg";
import UserssvgIcon from "@/wab/client/plasmic/q_4_icons/icons/PlasmicIcon__Userssvg";
import { useBrowserNotification } from "@/wab/client/utils/useBrowserNotification";
import { ensure } from "@/wab/common";
import { TeamId, WorkspaceId } from "@/wab/shared/ApiSchema";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { Dropdown, Menu } from "antd";
import * as _ from "lodash";
import { observer } from "mobx-react";
import * as React from "react";
import { useCallback, useState } from "react";
import { useHistory } from "react-router";

type DefaultLayoutProps = DefaultDefaultLayoutProps & {
  helpButton: PlasmicDefaultLayout__OverridesType["helpButton"];
};

const projectsCount = 4;

function DefaultLayout_(
  props: DefaultLayoutProps,
  ref: HTMLElementRefOf<"div">
) {
  const history = useHistory();
  const appCtx = useAppCtx();
  const userInfo = ensure(
    appCtx.selfInfo,
    "DefaultLayout requires appCtx to contain user information"
  );

  const [activeTeam, setActiveTeam] = React.useState<TeamId | undefined>(
    undefined
  );
  const [activeWorkspace, setActiveWorkspace] = React.useState<
    WorkspaceId | undefined
  >(undefined);

  const updateLocation = (path: string) => {
    const matchTeam = UU.org.parse(path);
    const matchTeamSettings = UU.orgSettings.parse(path);
    const matchWorkspace = UU.workspace.parse(path);

    setActiveTeam(
      (matchTeam?.params.teamId ||
        matchTeamSettings?.params.teamId ||
        appCtx.workspaces.find(
          (w) => w.id === matchWorkspace?.params.workspaceId
        )?.team.id) as TeamId | undefined
    );
    setActiveWorkspace(
      matchWorkspace?.params.workspaceId as WorkspaceId | undefined
    );
  };

  const teams = appCtx.getAllTeams();
  const workspaces = _.sortBy(appCtx.workspaces, (w) => w.name);
  const teamsToShow = teams.filter((t) => !activeTeam || activeTeam === t.id);
  const teamsOnTrial = teamsToShow.filter((t) => t.onTrial);
  const trialTeamToShow =
    teamsOnTrial.length > 0
      ? teamsOnTrial.reduce((a, b) =>
          a.trialStartDate! < b.trialStartDate! ? a : b
        )
      : teamsToShow.find((t) => recentlyEndedTrial(appCtx, t));

  React.useEffect(() => {
    updateLocation(history.location.pathname);
    const disposeHistory = history.listen((location) => {
      updateLocation(location.pathname);
    });

    return () => {
      disposeHistory();
    };
  }, [appCtx.teams, appCtx.workspaces]);

  const [showNewProjectModal, setShowNewProjectModal] = React.useState(false);

  useBrowserNotification();

  const userMenu = (
    <Menu>
      <Menu.Item>
        <PublicLink href={UU.settings.fill({})}>Settings</PublicLink>
      </Menu.Item>
      <Menu.Item
        onClick={async () => {
          await appCtx.logout();
        }}
      >
        Sign Out
      </Menu.Item>
    </Menu>
  );

  const brand =
    appCtx.appConfig.brands?.[activeTeam ?? ""] ??
    appCtx.appConfig.brands?.[""];

  const [newProjectWorkspaceId, setNewProjectWorkspaceId] =
    useState<WorkspaceId>();

  const requestNewProjectCreation = (workspaceId?: WorkspaceId) => {
    setNewProjectWorkspaceId(workspaceId);
    setShowNewProjectModal(true);
  };

  const projectCreationMenu = React.useMemo(
    () =>
      props.newProjectButtonAsDropdown ? (
        <Menu>
          {teams.map((team) => (
            <Menu.ItemGroup title={team.name}>
              {workspaces
                .filter((w) => w.team.id === team.id)
                .map((workspace) => (
                  <Menu.Item
                    onClick={() => requestNewProjectCreation(workspace.id)}
                  >
                    {workspace.name}
                  </Menu.Item>
                ))}
            </Menu.ItemGroup>
          ))}
          {teams.length === 0 && (
            <Menu.Item
              onClick={async () => {
                await promptNewTeam(appCtx, history);
              }}
            >
              No teams - <strong>create a team</strong>
            </Menu.Item>
          )}
        </Menu>
      ) : (
        <></>
      ),
    [teams, workspaces, props.newProjectButtonAsDropdown]
  );

  const [showAllClients, setShowAllClients] = useState<boolean>(false);
  const [userHackyNode, setUserHackyNode] = useState<React.ReactNode>();

  let activeOrganizationName =
    teams.length > 1 ? "Organizations" : teams[0]?.name || "Organization";

  const handleShowAllClients = useCallback(() => {
    setShowAllClients(!showAllClients);
  }, [showAllClients]);

  const organizationsMenu = (
    <Menu>
      {teams.map((t) => {
        if (activeTeam === t.id) {
          activeOrganizationName = t.name;
        }

        return (
          <Menu.Item>
            <PublicLink href={U.org({ teamId: t.id })}>{t.name}</PublicLink>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const UnorderedListIcon = (
    <UnorderedListsvgIcon
      role={"img"}
      style={{
        width: 16,
        height: 16,
        marginLeft: -2,
      }}
    />
  );

  const renderIdleButton = (_props: {
    title: string;
    href: string;
    tooltip?: string;
  }) => {
    return (
      // <Button
      //   withIcons={"startIcon"}
      //   startIcon={<UnorderedListsvgIcon role={"img"} />}
      //   type={["clear", "leftAligned"]}
      //   tooltip={_props.tooltip || "Explain the future feature..."}
      //   tooltipPlacement={"right"}
      // >
      //   {_props.title}
      // </Button>
      <NavButton href={_props.href} startIcon={UnorderedListIcon}>
        {_props.title}
      </NavButton>
    );
  };

  return (
    <>
      <PlasmicDefaultLayout
        root={{ ref }}
        {...props}
        headerLogoLink={{
          as: PublicLink,
          props: brand.logoHref
            ? {
                href: brand.logoHref,
              }
            : {},
        }}
        headerLogo={{
          render: () =>
            brand.logoImgSrc ? (
              <Logo src={brand.logoImgSrc} width={80} height={32} />
            ) : (
              <LogoFull width={120} height={32} />
            ),
        }}
        freeTrial={{
          // team: trialTeamToShow,
          render: () => null,
        }}
        allProjectsButton={{
          render: () => (
            <NavButton href={U.allProjects({})} startIcon={UnorderedListIcon}>
              {"All projects"}
            </NavButton>
          ),
        }}
        // teams={teams.map((t, i) => (
        //   <React.Fragment key={t.id}>
        //     <NavSeparator />
        //     <NavTeamSection
        //       name={t.name}
        //       href={U.org({ teamId: t.id })}
        //       selected={activeTeam === t.id}
        //       freeTrial={t.onTrial}
        //     >
        //       {workspaces
        //         .filter((w) => w.team.id === t.id)
        //         .map((w) => (
        //           <NavWorkspaceButton
        //             key={w.id}
        //             name={w.name}
        //             href={U.workspace({ workspaceId: w.id })}
        //             selected={activeWorkspace === w.id}
        //           />
        //         ))}
        //     </NavTeamSection>
        //   </React.Fragment>
        // ))}
        teams={[
          <NavSeparator />,
          <div className={sty.teamTitle}>{"Clients"}</div>,
          ...workspaces
            .slice(0, showAllClients ? undefined : projectsCount)
            .map((w) => (
              <NavButton
                href={U.workspace({ workspaceId: w.id })}
                selected={activeWorkspace === w.id}
                showStartIconContainer={false}
              >
                {w.name}
              </NavButton>
            )),
          workspaces.length > projectsCount ? (
            <Button
              type={["clearPrimary", "leftAligned"]}
              onClick={handleShowAllClients}
            >
              {showAllClients ? "Show less clients" : "Show all clients"}
            </Button>
          ) : null,
          <NavSeparator />,
          <div className={sty.teamTitle}>{"Manage"}</div>,
          renderIdleButton({
            title: "Pilot & decide",
            href: U.pilotDecide({}),
          }),
          renderIdleButton({
            title: "Plan & manage",
            href: U.planManage({}),
          }),
          renderIdleButton({
            title: "Deploy & Monitor",
            href: U.deployMonitor({}),
          }),
          <NavSeparator />,
          <div className={sty.teamTitle}>{"Team library"}</div>,
          renderIdleButton({
            title: "Templates",
            href: U.templates({}),
          }),
          renderIdleButton({
            title: "Plugins",
            href: U.plugins({}),
          }),
          renderIdleButton({
            title: "Custom code",
            href: U.customCode({}),
          }),
        ]}
        newProjectButton={
          props.newProjectButtonAsDropdown
            ? {
                wrap: (newProjectButton) => (
                  <Dropdown trigger={["click"]} overlay={projectCreationMenu}>
                    {newProjectButton}
                  </Dropdown>
                ),
              }
            : {
                onClick: () => requestNewProjectCreation(),
              }
        }
        hideStarters={true}
        upgradeButton={
          // teams.some((t) => canUpgradeTeam(appCtx, t))
          //   ? {
          //       onClick: async () => {
          //         const { tiers } = await appCtx.api.listCurrentFeatureTiers();
          //         await promptBilling({
          //           appCtx,
          //           availableTiers: tiers,
          //           title: "",
          //           target: {},
          //         });
          //       },
          //     }
          //   :
          {
            render: () => null,
          }
        }
        newTeamButton={{
          // onClick: async () => {
          //   await promptNewTeam(appCtx, history);
          // },
          render: () => null,
        }}
        myProjectsButton={{
          render: () => null,
        }}
        headerActions={{
          wrap: (node) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {teams.length > 1 ? (
                <Dropdown
                  overlay={organizationsMenu}
                  placement="topRight"
                  trigger={["click"]}
                >
                  <NavButton
                    startIcon={<UserssvgIcon role={"img"} />}
                    endIcon={<TriangleBottomIcon role={"img"} />}
                    withEndIcon={true}
                  >
                    {activeOrganizationName}
                  </NavButton>
                </Dropdown>
              ) : (
                <NavButton
                  href={teams[0] ? U.org({ teamId: teams[0].id }) : undefined}
                  startIcon={<UserssvgIcon role={"img"} />}
                >
                  {activeOrganizationName}
                </NavButton>
              )}
              {node}
              {userHackyNode && (
                <Dropdown
                  overlay={userMenu}
                  placement="topLeft"
                  trigger={["click"]}
                >
                  {userHackyNode}
                </Dropdown>
              )}
            </div>
          ),
        }}
        helpButton={{
          render: () => null,
        }}
        documentationButton={{
          render: () => null,
        }}
        userButton={{
          props: {
            children: userInfo.firstName,
            "data-test-id": "btn-dashboard-user",
          },
          wrap: (node) => {
            if (!userHackyNode) {
              setUserHackyNode(node);
            }

            // return (
            //   <Dropdown
            //     overlay={userMenu}
            //     placement="topLeft"
            //     trigger={["click"]}
            //   >
            //     {node}
            //   </Dropdown>
            // );

            return null;
          },
        }}
        avatar={<Avatar size="small" user={userInfo} />}
      />
      {showNewProjectModal && newProjectWorkspaceId && (
        <NewProjectModals
          workspaceId={newProjectWorkspaceId}
          onCancel={() => setShowNewProjectModal(false)}
        />
      )}
    </>
  );
}

const DefaultLayout = observer(React.forwardRef(DefaultLayout_));
export default DefaultLayout;
