// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import { MenuMaker } from "@/wab/client/components/widgets";
import {
  DefaultLabeledListItemProps,
  PlasmicLabeledListItem,
} from "@/wab/client/plasmic/plasmic_kit_new_design_system_former_style_controls/PlasmicLabeledListItem";
import { combineProps, swallowClick } from "@/wab/commons/components/ReactUtil";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import * as React from "react";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";

export interface LabeledListItemProps
  extends Omit<
    DefaultLabeledListItemProps,
    "clickable" | "withMenu" | "onClick" | "withIndicator"
  > {
  menu?: React.ReactNode | MenuMaker;
  noMenuButton?: boolean;
  onClick?: (event: React.MouseEvent) => void;
  onDelete?: (event: React.MouseEvent) => void;
  onContextMenu?: (event: React.MouseEvent) => void;
  indicator?: React.ReactNode | true;
  "data-test-id"?: string;
  dragHandleProps?: DraggableProvidedDragHandleProps;
}

function LabeledListItem_(
  props: LabeledListItemProps,
  ref: HTMLElementRefOf<"div">
) {
  const [hover, setHover] = React.useState(false);
  const {
    menu,
    noMenuButton,
    onClick,
    onDelete,
    onContextMenu,
    indicator,
    dragHandleProps,
    "data-test-id": dataTestId,
    ...rest
  } = props;
  const hasActions =
    !!onClick ||
    (!noMenuButton && menu) ||
    !!onDelete ||
    rest.moreActionButtons;
  return (
    <PlasmicLabeledListItem
      root={{
        ref,
        onClick,
        onContextMenu,
        "data-plasmic-role": "labeled-item",
        "data-test-id": dataTestId,
        onMouseEnter: () => setHover(true),
        onMouseLeave: () => setHover(false),
      }}
      {...rest}
      withMenu={!!menu}
      clickable={!!onClick}
      menuButton={
        menu
          ? {
              props: {
                menu,
              },
            }
          : undefined
      }
      dragHandle={{
        props:
          dragHandleProps && props.draggable
            ? combineProps(dragHandleProps, {
                onMouseDown: (e: React.MouseEvent) => {
                  // We explicitly stopPropagation on mouse down on the drag handler,
                  // after dragHandleProps() have had a chance to handle it, so that
                  // we don't propagate up to any other drag handler while this ListItem
                  // is being dragged by the dragHandle.  For example, this ListItem
                  // could've been also wrapped by another DraggableInsertable.
                  e.stopPropagation();
                },
                style: {
                  display: hover ? "flex" : "none",
                },
              })
            : { display: "none" },
      }}
      deletable={!!onDelete}
      deleteButton={onDelete ? { onClick: onDelete } : undefined}
      indicatorContainer={
        indicator && typeof indicator === "object"
          ? {
              children: indicator,
            }
          : undefined
      }
      withIndicator={!!indicator}
      actionsContainer={
        hasActions
          ? {
              onClick: swallowClick,
            }
          : { style: { display: "none" } }
      }
    />
  );
}

export const LabeledListItem = React.forwardRef(LabeledListItem_);
export default LabeledListItem;
