import { U } from "@/wab/client/cli-routes";
import { useAppCtx } from "@/wab/client/contexts/AppContexts";
import {
  DefaultNewProjectModalProps,
  PlasmicNewProjectModal,
} from "@/wab/client/plasmic/plasmic_kit_dashboard/PlasmicNewProjectModal";
import { WorkspaceId } from "@/wab/shared/ApiSchema";
import { observer } from "mobx-react";
import * as React from "react";
import { BareModal } from "./studio/BareModal";
import {
  TemplateFormGroupKey,
  TemplateFormStep,
  templateFormSteps,
} from "@/wab/shared/form/templateForm";
import Select from "@/wab/client/components/widgets/Select";
import Checkbox from "@/wab/client/components/widgets/Checkbox";
import ArrowLeftIcon from "@/wab/client/plasmic/plasmic_kit/PlasmicIcon__ArrowLeft";
import ArrowRightIcon from "@/wab/client/plasmic/plasmic_kit/PlasmicIcon__ArrowRight";
import { templateFormEnumsTrans } from "@/wab/shared/form/templateFormEnumsTrans";
import { enumKeys } from "@/wab/shared/utils/enum-utils";
import sty from "./NewProjectFormModal.module.scss";
import {
  TemplateFormResolver,
  TemplateFormResolverForm,
} from "@/wab/shared/form/templateFormResolver";
import { templateFormConfig } from "@/wab/shared/form/templateFormConfig";
import Button from "./widgets/Button";
import classNames from "classnames";

interface NewProjectFormModalProps extends DefaultNewProjectModalProps {
  onCancel: () => void;
  workspaceId?: WorkspaceId;
}

const firstStep = Math.min(...templateFormSteps) as TemplateFormStep;
const lastStep = Math.max(...templateFormSteps) as TemplateFormStep;

const NewProjectFormModal = observer(function NewProjectFormModal({
  workspaceId,
  onCancel,
  ...rest
}: NewProjectFormModalProps) {
  const appCtx = useAppCtx();

  const [form, setForm] = React.useState({} as TemplateFormResolverForm);
  const [currentStep, setCurrentStep] = React.useState(firstStep);
  const [isCreating, setCreating] = React.useState(false);

  const resolver = new TemplateFormResolver(form, null);
  const config = templateFormConfig(
    resolver,
    appCtx.appConfig.templateFormConfig
  );

  const step = config.steps[currentStep];
  const stepTrans = templateFormEnumsTrans.steps[currentStep];
  const stepValid = !step.states.find(
    (s) => s.required && !form[s.group]?.length
  );

  const cloneProject = async () => {
    const template = config.templates.find((t) => t.condition);

    if (template) {
      setCreating(true);

      try {
        const { projectId } = await appCtx.app.withSpinner(
          appCtx.api.clonePublishedTemplate(
            template.plasmic,
            "New project",
            workspaceId
          )
        );

        if (template.jira) {
          await appCtx.app.withSpinner(
            appCtx.api.initJiraProject(projectId, template.jira)
          );
        }

        location.href = U.project({ projectId });
      } catch (e) {
        setCreating(false);
      }
    }
  };

  const handleCheckbox =
    (group: TemplateFormGroupKey, key: any) => (value: any) => {
      setForm((oldForm) => {
        const newGroup = (oldForm[group] || []).filter((s) => s !== key);

        return { ...oldForm, [group]: value ? [...newGroup, key] : newGroup };
      });
    };

  const handleSelect = (group: TemplateFormGroupKey) => (value: any) => {
    setForm((oldForm) => {
      return { ...oldForm, [group]: [value] };
    });
  };

  const handleBack = () => {
    const findPrevStep = (s: number) => {
      const prevStep = Math.max(firstStep, s);
      const prevStepConfig = config.steps[prevStep];

      return s > firstStep && prevStepConfig.skip ? findPrevStep(s - 1) : s;
    };

    setCurrentStep(findPrevStep(currentStep - 1) as any);
  };

  const handleNext = () => {
    const findNextStep = (s: number) => {
      const nextStep = Math.min(lastStep, s);
      const nextStepConfig = config.steps[nextStep];

      return s < lastStep && nextStepConfig.skip ? findNextStep(s + 1) : s;
    };

    if (stepValid) {
      if (currentStep === lastStep) {
        void cloneProject();
      } else {
        setCurrentStep(findNextStep(currentStep + 1) as any);
      }
    }
  };

  return (
    <BareModal onClose={onCancel} width={1450} style={{ top: 32 }}>
      <PlasmicNewProjectModal
        {...rest}
        className={sty.reset}
        root={{
          style: {
            maxHeight: `calc(100vh - 64px)`,
          },
        }}
        cancelButton={{
          onClick: onCancel,
        }}
      >
        <div className={sty.top}>
          <div className={sty.dotsGroup}>
            {templateFormSteps.map((s) => (
              <div
                className={classNames([
                  sty.dot,
                  currentStep === s && sty.dotActive,
                ])}
              />
            ))}
          </div>

          {currentStep > firstStep && (
            <Button
              className={sty.backButton}
              startIcon={<ArrowLeftIcon role={"img"} />}
              withIcons={["startIcon"]}
              onClick={handleBack}
            >
              {templateFormEnumsTrans.misc.back}
            </Button>
          )}
        </div>

        {currentStep > firstStep && (
          <div className={sty.choosen}>
            {templateFormEnumsTrans.misc.choosen}{" "}
            {
              templateFormEnumsTrans.groups.BusinessType.values[
                form.BusinessType?.[0] || ""
              ]
            }
          </div>
        )}

        {stepTrans.title && <p className={sty.title}>{stepTrans.title}</p>}
        {stepTrans.description && (
          <p className={sty.description}>{stepTrans.description}</p>
        )}

        {step.states.map((state) => {
          if (!state.self) {
            return null;
          }

          const stateTrans = templateFormEnumsTrans.groups[state.group];
          const stateValues = enumKeys(config.groups[state.group]).reduce(
            (acc: any[], key: any) => {
              if (state.children?.[key] !== false) {
                acc.push(key);
              }

              return acc;
            },
            []
          );

          return (
            <>
              {stateTrans.title && (
                <p className={sty.title}>{stateTrans.title}</p>
              )}
              {stateTrans.description && (
                <p className={sty.description}>{stateTrans.description}</p>
              )}

              {state.unique ? (
                <Select
                  className={sty.select}
                  type={"bordered"}
                  placeholder={stateTrans.placeholder || undefined}
                  value={(form[state.group] || [])[0]}
                  options={stateValues.map((stepValue) => ({
                    value: stepValue,
                    label: stateTrans.values?.[stepValue],
                  }))}
                  onChange={handleSelect(state.group)}
                />
              ) : (
                <div className={sty.checkboxesGroup}>
                  {stateValues.map((stepValue) => (
                    <Checkbox
                      className={sty.checkbox}
                      isChecked={
                        (form[state.group] || []).indexOf(stepValue) !== -1
                      }
                      medium={true}
                      onChange={handleCheckbox(state.group, stepValue)}
                    >
                      {stateTrans.values?.[stepValue]}
                    </Checkbox>
                  ))}
                </div>
              )}
            </>
          );
        })}

        <Button
          className={sty.nextButton}
          type={["primary"]}
          endIcon={<ArrowRightIcon role={"img"} />}
          withIcons={currentStep === lastStep ? undefined : ["endIcon"]}
          disabled={!stepValid || isCreating}
          onClick={!stepValid || isCreating ? undefined : handleNext}
        >
          {currentStep === lastStep
            ? templateFormEnumsTrans.misc.start
            : templateFormEnumsTrans.misc.next}
        </Button>
      </PlasmicNewProjectModal>
    </BareModal>
  );
});

export default NewProjectFormModal;
