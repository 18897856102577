import { fullApiPath } from "@/wab/client/api";
import { U } from "@/wab/client/cli-routes";
import { Spinner } from "@/wab/client/components/widgets";
import * as React from "react";
import { NotionRenderer } from "react-notion-x";
import "react-notion-x/src/styles.css";
import sty from "./NotionContentPage.module.css";

interface NotionContentPageProps {
  code: string;
}

export default function NotionContentPage(props: NotionContentPageProps) {
  const { code } = props;

  const [recordMap, setRecordMap] = React.useState<any>();

  React.useEffect(() => {
    void fetch(fullApiPath(U.notionPage({ code }))).then((response) => {
      void response.json().then((data) => {
        setRecordMap(data);
      });
    });
  }, []);

  return (
    <div className={sty.content}>
      {recordMap?.block ? (
        <NotionRenderer
          recordMap={recordMap}
          fullPage={true}
          darkMode={false}
        />
      ) : (
        <Spinner />
      )}
    </div>
  );
}
