// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import {
  DefaultFilterButtonProps,
  PlasmicFilterButton,
} from "@/wab/client/plasmic/plasmic_kit_left_pane/PlasmicFilterButton";
import { Dropdown, Tooltip } from "antd";
import * as React from "react";

export interface FilterButtonProps extends DefaultFilterButtonProps {
  tooltip?: React.ReactNode;
  overlay?: React.ComponentProps<typeof Dropdown>["overlay"];
}

function FilterButton(props: FilterButtonProps) {
  const { tooltip, overlay, ...rest } = props;
  return (
    <Tooltip title={tooltip}>
      <Dropdown overlay={overlay} trigger={["click"]}>
        <PlasmicFilterButton {...rest} />
      </Dropdown>
    </Tooltip>
  );
}

export default FilterButton;
