export const TAG_TO_HTML_INTERFACE = {
  a: "HTMLAnchorElement",
  abbr: "HTMLElement",
  address: "HTMLElement",
  area: "HTMLAreaElement",
  article: "HTMLElement",
  aside: "HTMLElement",
  audio: "HTMLAudioElement",
  b: "HTMLElement",
  base: "HTMLBaseElement",
  bdi: "HTMLElement",
  bdo: "HTMLElement",
  blockquote: "HTMLQuoteElement",
  body: "HTMLBodyElement",
  br: "HTMLBRElement",
  button: "HTMLButtonElement",
  canvas: "HTMLCanvasElement",
  caption: "HTMLTableCaptionElement",
  cite: "HTMLElement",
  code: "HTMLElement",
  col: "HTMLTableColElement",
  colgroup: "HTMLTableColElement",
  data: "HTMLDataElement",
  datalist: "HTMLDataListElement",
  dd: "HTMLElement",
  del: "HTMLModElement",
  details: "HTMLDetailsElement",
  dfn: "HTMLElement",
  dialog: "HTMLDialogElement",
  div: "HTMLDivElement",
  dl: "HTMLDListElement",
  dt: "HTMLElement",
  em: "HTMLElement",
  embed: "HTMLEmbedElement",
  fieldset: "HTMLFieldSetElement",
  figcaption: "HTMLElement",
  figure: "HTMLElement",
  footer: "HTMLElement",
  form: "HTMLFormElement",
  h1: "HTMLHeadingElement",
  h2: "HTMLHeadingElement",
  h3: "HTMLHeadingElement",
  h4: "HTMLHeadingElement",
  h5: "HTMLHeadingElement",
  h6: "HTMLHeadingElement",
  head: "HTMLHeadElement",
  header: "HTMLElement",
  hgroup: "HTMLElement",
  hr: "HTMLHRElement",
  html: "HTMLHtmlElement",
  i: "HTMLElement",
  iframe: "HTMLIFrameElement",
  img: "HTMLImageElement",
  input: "HTMLInputElement",
  ins: "HTMLModElement",
  kbd: "HTMLElement",
  label: "HTMLLabelElement",
  legend: "HTMLLegendElement",
  li: "HTMLLIElement",
  link: "HTMLLinkElement",
  main: "HTMLElement",
  map: "HTMLMapElement",
  mark: "HTMLElement",
  menu: "HTMLMenuElement",
  meta: "HTMLMetaElement",
  meter: "HTMLMeterElement",
  nav: "HTMLElement",
  noscript: "HTMLElement",
  object: "HTMLObjectElement",
  ol: "HTMLOListElement",
  optgroup: "HTMLOptGroupElement",
  option: "HTMLOptionElement",
  output: "HTMLOutputElement",
  p: "HTMLParagraphElement",
  picture: "HTMLPictureElement",
  pre: "HTMLPreElement",
  progress: "HTMLProgressElement",
  q: "HTMLQuoteElement",
  rp: "HTMLElement",
  rt: "HTMLElement",
  ruby: "HTMLElement",
  s: "HTMLElement",
  samp: "HTMLElement",
  script: "HTMLScriptElement",
  section: "HTMLElement",
  select: "HTMLSelectElement",
  slot: "HTMLSlotElement",
  small: "HTMLElement",
  source: "HTMLSourceElement",
  span: "HTMLSpanElement",
  strong: "HTMLElement",
  style: "HTMLStyleElement",
  sub: "HTMLElement",
  summary: "HTMLElement",
  sup: "HTMLElement",
  table: "HTMLTableElement",
  tbody: "HTMLTableSectionElement",
  td: "HTMLTableCellElement",
  template: "HTMLTemplateElement",
  textarea: "HTMLTextAreaElement",
  tfoot: "HTMLTableSectionElement",
  th: "HTMLTableCellElement",
  thead: "HTMLTableSectionElement",
  time: "HTMLTimeElement",
  title: "HTMLTitleElement",
  tr: "HTMLTableRowElement",
  track: "HTMLTrackElement",
  u: "HTMLElement",
  ul: "HTMLUListElement",
  var: "HTMLElement",
  video: "HTMLVideoElement",
  wbr: "HTMLElement",
};

export const TAG_TO_HTML_ATTRIBUTES = {
  a: "AnchorHTMLAttributes",
  abbr: "HTMLAttributes",
  address: "HTMLAttributes",
  area: "AreaHTMLAttributes",
  article: "HTMLAttributes",
  aside: "HTMLAttributes",
  audio: "AudioHTMLAttributes",
  b: "HTMLAttributes",
  base: "BaseHTMLAttributes",
  bdi: "HTMLAttributes",
  bdo: "HTMLAttributes",
  blockquote: "BlockquoteHTMLAttributes",
  body: "HTMLAttributes",
  br: "HTMLAttributes",
  button: "ButtonHTMLAttributes",
  canvas: "CanvasHTMLAttributes",
  caption: "HTMLAttributes",
  cite: "HTMLAttributes",
  code: "HTMLAttributes",
  col: "ColHTMLAttributes",
  colgroup: "ColgroupHTMLAttributes",
  data: "DataHTMLAttributes",
  datalist: "HTMLAttributes",
  dd: "HTMLAttributes",
  del: "DelHTMLAttributes",
  details: "DetailsHTMLAttributes",
  dfn: "HTMLAttributes",
  dialog: "DialogHTMLAttributes",
  div: "HTMLAttributes",
  dl: "HTMLAttributes",
  dt: "HTMLAttributes",
  em: "HTMLAttributes",
  embed: "EmbedHTMLAttributes",
  fieldset: "FieldsetHTMLAttributes",
  figcaption: "HTMLAttributes",
  figure: "HTMLAttributes",
  footer: "HTMLAttributes",
  form: "FormHTMLAttributes",
  h1: "HTMLAttributes",
  h2: "HTMLAttributes",
  h3: "HTMLAttributes",
  h4: "HTMLAttributes",
  h5: "HTMLAttributes",
  h6: "HTMLAttributes",
  head: "HTMLAttributes",
  header: "HTMLAttributes",
  hgroup: "HTMLAttributes",
  hr: "HTMLAttributes",
  html: "HtmlHTMLAttributes",
  i: "HTMLAttributes",
  iframe: "IframeHTMLAttributes",
  img: "ImgHTMLAttributes",
  input: "InputHTMLAttributes",
  ins: "InsHTMLAttributes",
  kbd: "HTMLAttributes",
  label: "LabelHTMLAttributes",
  legend: "HTMLAttributes",
  li: "LiHTMLAttributes",
  link: "LinkHTMLAttributes",
  main: "HTMLAttributes",
  map: "MapHTMLAttributes",
  mark: "HTMLAttributes",
  menu: "MenuHTMLAttributes",
  meta: "MetaHTMLAttributes",
  meter: "MeterHTMLAttributes",
  nav: "HTMLAttributes",
  noscript: "HTMLAttributes",
  object: "ObjectHTMLAttributes",
  ol: "OlHTMLAttributes",
  optgroup: "OptgroupHTMLAttributes",
  option: "OptionHTMLAttributes",
  output: "OutputHTMLAttributes",
  p: "HTMLAttributes",
  picture: "HTMLAttributes",
  pre: "HTMLAttributes",
  progress: "ProgressHTMLAttributes",
  q: "QuoteHTMLAttributes",
  rp: "HTMLAttributes",
  rt: "HTMLAttributes",
  ruby: "HTMLAttributes",
  s: "HTMLAttributes",
  samp: "HTMLAttributes",
  script: "ScriptHTMLAttributes",
  section: "HTMLAttributes",
  select: "SelectHTMLAttributes",
  slot: "SlotHTMLAttributes",
  small: "HTMLAttributes",
  source: "SourceHTMLAttributes",
  span: "HTMLAttributes",
  strong: "HTMLAttributes",
  style: "StyleHTMLAttributes",
  sub: "HTMLAttributes",
  summary: "HTMLAttributes",
  sup: "HTMLAttributes",
  table: "TableHTMLAttributes",
  tbody: "HTMLAttributes",
  td: "TdHTMLAttributes",
  template: "HTMLAttributes",
  textarea: "TextareaHTMLAttributes",
  tfoot: "HTMLAttributes",
  th: "ThHTMLAttributes",
  thead: "HTMLAttributes",
  time: "TimeHTMLAttributes",
  title: "HTMLAttributes",
  tr: "HTMLAttributes",
  track: "TrackHTMLAttributes",
  u: "HTMLAttributes",
  ul: "HTMLAttributes",
  var: "HTMLAttributes",
  video: "VideoHTMLAttributes",
  wbr: "HTMLAttributes",
};
