import NotionContentPage from "@/wab/client/components/notion/NotionContentPage";
import * as React from "react";
import DefaultLayout from "./DefaultLayout";
import NavTeamSection from "./NavTeamSection";

interface NotionPageProps {
  code: string;
}

export default function NotionPage(props: NotionPageProps) {
  const { code } = props;

  return (
    <DefaultLayout
      hideNewProjectButton={true}
      helpButton={{}}
      teams={<NavTeamSection />}
    >
      <NotionContentPage code={code} />
    </DefaultLayout>
  );
}
