// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import {
  DefaultListSectionHeaderProps,
  PlasmicListSectionHeader,
} from "@/wab/client/plasmic/plasmic_kit_design_system/PlasmicListSectionHeader";
import * as React from "react";

// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface ListSectionHeaderProps extends Omit<DefaultListSectionHeaderProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultListSectionHeaderProps altogether and have
// total control over the props for your component.
interface ListSectionHeaderProps extends DefaultListSectionHeaderProps {
  style?: React.CSSProperties;
  onToggle?: () => void;
}

function ListSectionHeader(props: ListSectionHeaderProps) {
  const { onToggle, style, ...rest } = props;
  return (
    <PlasmicListSectionHeader
      {...rest}
      role="presentation"
      expandButton={{
        onClick: onToggle,
      }}
      root={{
        style,
        onClick: onToggle,
      }}
    />
  );
}

export default ListSectionHeader;
