// This is a skeleton starter React component generated by Plasmic.
// Feel free to edit as you see fit.
import {
  PlasmicTextbox,
  PlasmicTextbox__VariantsArgs,
} from "@/wab/client/plasmic/plasmic_kit/PlasmicTextbox";
import { ensure } from "@/wab/common";
import React, { ReactNode, useEffect } from "react";
import { useMount, useMountedState } from "react-use";

type TextboxProps = Omit<React.ComponentProps<"input">, "disabled"> & {
  prefixIcon?: ReactNode;
  suffixIcon?: ReactNode;
  withIcons?: PlasmicTextbox__VariantsArgs["withIcons"];
  disabled?: PlasmicTextbox__VariantsArgs["disabled"];
  styleType?: PlasmicTextbox__VariantsArgs["styleType"];
  error?: PlasmicTextbox__VariantsArgs["error"];
  fontSize?: PlasmicTextbox__VariantsArgs["fontSize"];
  fontStyle?: PlasmicTextbox__VariantsArgs["fontStyle"];
  noOutline?: PlasmicTextbox__VariantsArgs["noOutline"];
  whiteBackground?: PlasmicTextbox__VariantsArgs["whiteBackground"];
  extraPadding?: PlasmicTextbox__VariantsArgs["extraPadding"];
  className?: string;
  inputClassName?: string;
  onEdit?: (value: string) => void;
  onEnter?: (value: string) => void;
  onEscape?: (value: string) => void;
  skipEditOnEnter?: boolean;
  skipEditOnBlur?: boolean;
  selectAllOnAutoFocus?: boolean;
  selectAllOnFocus?: boolean;
  children?: never;
  wrapperProps?: React.ComponentProps<"div">;
};

type X = { x: number | string | null } & { x: string | number };

export interface TextboxRef {
  value: () => string;
  setValue: (val: string) => void;
  focus: () => void;
  select: () => void;
  blur: () => void;
  input: () => HTMLInputElement;
  dom: () => HTMLDivElement;
}

export const Textbox = React.forwardRef(
  (props: TextboxProps, ref: React.Ref<TextboxRef>) => {
    const {
      prefixIcon,
      suffixIcon,
      withIcons,
      disabled,
      styleType,
      error,
      fontSize,
      fontStyle,
      noOutline,
      whiteBackground,
      extraPadding,
      className,
      inputClassName,
      skipEditOnBlur,
      skipEditOnEnter,
      onChange,
      onEdit,
      onEnter,
      onEscape,
      onBlur,
      onFocus,
      onKeyDown,
      onKeyPress,
      selectAllOnAutoFocus,
      selectAllOnFocus,
      wrapperProps,
      ...restInputProps
    } = props;
    const [edited, setEdited] = React.useState(false);
    const inputRef = React.useRef<HTMLInputElement | null>(null);
    const rootRef = React.useRef<HTMLDivElement>(null);

    const getInput = () => ensure(inputRef.current, "Must already be rendered");
    const getValue = () => getInput().value;
    const isMounted = useMountedState();
    const focus = () => {
      getInput().focus();
      if (selectAllOnFocus) {
        getInput().select();
      }
    };

    React.useImperativeHandle(ref, () => ({
      value: () => getValue(),
      setValue: (val: string) => (getInput().value = val),
      focus,
      select: () => getInput().select(),
      blur: () => getInput().blur(),
      input: () => getInput(),
      dom: () => ensure(rootRef.current, "Must already be rendered"),
    }));

    useMount(() => {
      // React's autoFocus doesn't always work when rendered in an antd Modal.
      // Perhaps React's autoFocus code was run before antd Modal was attached to the DOM?
      // See https://blog.maisie.ink/react-ref-autofocus/#does-it-always-work
      //
      // So we manually focus just in case.
      if (props.autoFocus) {
        focus();
      }
    });

    // Must not use useUnmount due to React 17 cleanup: https://reactjs.org/blog/2020/08/10/react-v17-rc.html#effect-cleanup-timing
    useEffect(() => {
      const input = getInput();
      // Upon unmounting, onBlur will not have a chance to fire, so
      // if we intended to call onEdit() onBlur(), we gotta do it now
      return () => {
        if (
          // We check !isMounted() because the effect disposal function
          // is not only called upon unmounting; it is called whenver
          // the effect dependencies change (specifically `edited` in this case)
          !isMounted() &&
          edited &&
          onEdit != null &&
          !(onBlur && skipEditOnBlur)
        ) {
          onEdit(input.value);
        }
      };
    }, [edited, isMounted]);

    return (
      <PlasmicTextbox
        variants={{
          disabled: disabled,
          styleType: styleType,
          withIcons,
          error: error,
          fontSize,
          fontStyle,
          noOutline,
          whiteBackground,
          extraPadding,
        }}
        args={{
          prefixIcon: prefixIcon,
          suffixIcon: suffixIcon,
        }}
        overrides={{
          root: {
            className: props.className,
            ref: rootRef,
            ...wrapperProps,
          },

          textbox: {
            className: inputClassName,
            disabled: !!disabled,
            ref: inputRef,
            type: "text",
            onChange: (e) => {
              onChange?.(e);
              setEdited(true);
            },
            onFocus: (e) => {
              onFocus?.(e);
              if (selectAllOnFocus) {
                // this.ref is not updated when the input is autoFocused
                e.target.select();
              }
            },
            onBlur: (e) => {
              onBlur?.(e);
              if (edited && onEdit != null && !(onBlur && skipEditOnBlur)) {
                onEdit(getValue());
                setEdited(false);
              }
            },
            onKeyDown: (e) => {
              onKeyDown?.(e);
              if (e.key === "Escape") {
                onEscape?.(getValue());
              }
            },
            onKeyPress: (e) => {
              onKeyPress?.(e);
              if (e.key === "Enter") {
                if (edited && onEdit != null && !(onEnter && skipEditOnEnter)) {
                  onEdit(getValue());
                }
                onEnter?.(getValue());
              }
            },
            ...restInputProps,
            value: restInputProps.value ?? undefined,
          },
        }}
      />
    );
  }
);

export default Textbox as React.FunctionComponent<
  React.ComponentProps<typeof Textbox>
>;
