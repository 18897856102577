import NewProjectFormModal from "@/wab/client/components/NewProjectFormModal";
import NewProjectModal from "@/wab/client/components/NewProjectModal";
import NewProjectStartModal from "@/wab/client/components/NewProjectStartModal";
import { WorkspaceId } from "@/wab/shared/ApiSchema";
import * as React from "react";

interface NewProjectModalsProps {
  workspaceId: WorkspaceId;
  onCancel?: () => void;
}

function NewProjectModals({ workspaceId, onCancel }: NewProjectModalsProps) {
  const [showNewProjectModal, setShowNewProjectModal] = React.useState(true);
  const [showNewProjectFormModal, setShowNewProjectFormModal] =
    React.useState(false);
  const [showNewProjectTemplatesModal, setShowNewProjectTemplatesModal] =
    React.useState(false);

  return (
    <>
      {showNewProjectModal && (
        <NewProjectStartModal
          workspaceId={workspaceId}
          onForm={() => {
            setShowNewProjectModal(false);
            setShowNewProjectFormModal(true);
          }}
          onTemplate={() => {
            setShowNewProjectModal(false);
            setShowNewProjectTemplatesModal(true);
          }}
          onCancel={() => {
            setShowNewProjectModal(false);
            onCancel?.();
          }}
        />
      )}
      {showNewProjectFormModal && (
        <NewProjectFormModal
          workspaceId={workspaceId}
          onCancel={() => {
            setShowNewProjectFormModal(false);
            onCancel?.();
          }}
        />
      )}
      {showNewProjectTemplatesModal && (
        <NewProjectModal
          workspaceId={workspaceId}
          onCancel={() => {
            setShowNewProjectTemplatesModal(false);
            onCancel?.();
          }}
        />
      )}
    </>
  );
}

export default NewProjectModals;
