export const enumKeys = (enumerable: any) => {
  const values = Object.values(enumerable);

  return typeof values[values.length - 1] === "number"
    ? values.slice(0, values.length / 2)
    : Object.keys(enumerable);
};

export const enumValues = (enumerable: any) => {
  const values = Object.values(enumerable);

  return typeof values[values.length - 1] === "number"
    ? values.slice(values.length / 2)
    : values;
};
