import { zitadel } from "@/wab/client/auth/zitadel";
import { useAppCtx } from "@/wab/client/contexts/AppContexts";
import * as React from "react";
import { IntakeFlowForm } from "./IntakeFlowForm";

export function AuthFormCallback() {
  const appCtx = useAppCtx();

  React.useEffect(() => {
    zitadel.userManager
      .signinRedirectCallback()
      .then((data) => {
        const accessToken = data.access_token;
        const refreshToken = data.refresh_token;

        void appCtx.api
          .zitadelLogin(accessToken, refreshToken)
          .then((response) => {
            if (response.status === true) {
              window.location.href = "/";
            } else {
              //
            }
          });
      })
      .catch((error) => {
        //
      });
  }, []);

  return (
    <IntakeFlowForm>
      <div className={"LoginForm__Controls"}>
        <p className={"LoginForm__Title"}>Welcome Back!</p>
        <p className={"LoginForm__Description"}>We are logging you in.</p>
        <div className={"LoginForm__Oauth"}>
          <p className={"LoginForm__Description"}>Loading...</p>
        </div>
      </div>
    </IntakeFlowForm>
  );
}
