import { Arena, Expr, isKnownArena, isKnownTplNode } from "@/wab/classes";
/** @format */

// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import {
  Component,
  ImageAsset,
  ProjectDependency,
  TplNode,
} from "@/wab/classes";
import {
  getComponentPresets,
  Preset,
} from "@/wab/client/code-components/code-presets";
import { useFocusManager } from "@/wab/client/components/aria-utils";
import {
  AsChildInsertRelLoc,
  AsSiblingInsertRelLoc,
  getFocusedInsertAnchor,
  getValidInsertLocs,
  InsertRelLoc,
  isAsChildRelLoc,
  isAsSiblingRelLoc,
} from "@/wab/client/components/canvas/view-ops";
import ListSectionHeader from "@/wab/client/components/ListSectionHeader";
import ListSectionSeparator from "@/wab/client/components/ListSectionSeparator";
import {
  checkAndNotifyUnsupportedHostVersion,
  checkAndNotifyUnsupportedReactVersion,
  notifyCodeLibraryInsertion,
} from "@/wab/client/components/modals/codeComponentModals";
import { getPreInsertionProps } from "@/wab/client/components/modals/PreInsertionModal";
import {
  getPlumeComponentTemplates,
  getPlumeImage,
} from "@/wab/client/components/plume/plume-display-utils";
import { PlumyIcon } from "@/wab/client/components/plume/plume-markers";
import sty from "@/wab/client/components/studio/add-drawer/AddDrawer.module.css";
import AddDrawerItem from "@/wab/client/components/studio/add-drawer/AddDrawerItem";
import { AddItemGroup } from "@/wab/client/components/studio/add-drawer/AddDrawerSection";
import { DraggableInsertable } from "@/wab/client/components/studio/add-drawer/DraggableInsertable";
import { ImagePreview } from "@/wab/client/components/style-controls/ImageSelector";
import { Matcher } from "@/wab/client/components/view-common";
import { Icon } from "@/wab/client/components/widgets/Icon";
import { TextboxRef } from "@/wab/client/components/widgets/Textbox";
import {
  AddFakeItem,
  AddInstallableItem,
  AddItem,
  AddItemType,
  AddTplItem,
  INSERTABLES_MAP,
  isTplAddItem,
} from "@/wab/client/definitions/insertables";
import { useVirtualCombobox } from "@/wab/client/hooks/useVirtualCombobox";
import {
  COMBINATION_ICON,
  COMPONENT_ICON,
  GROUP_ICON,
} from "@/wab/client/icons";
import {
  buildInsertableExtraInfo,
  getHostLessDependenciesToInsertableTemplate,
  getScreenVariantToInsertableTemplate,
  postInsertableTemplate,
} from "@/wab/client/insertable-templates";
import ComponentIcon from "@/wab/client/plasmic/plasmic_kit/PlasmicIcon__Component";
import PlumeMarkIcon from "@/wab/client/plasmic/plasmic_kit_design_system/icons/PlasmicIcon__PlumeMark";
import { PlasmicAddDrawer } from "@/wab/client/plasmic/plasmic_kit_left_pane/PlasmicAddDrawer";
import { StudioCtx } from "@/wab/client/studio-ctx/StudioCtx";
import { ViewCtx } from "@/wab/client/studio-ctx/view-ctx";
import { trackEvent } from "@/wab/client/tracking";
import {
  assert,
  cx,
  ensure,
  ensureArray,
  filterFalsy,
  hackyCast,
  replaceAll,
  spawn,
  spawnWrapper,
  withoutNils,
} from "@/wab/common";
import { MaybeWrap } from "@/wab/commons/components/ReactUtil";
import {
  CodeComponent,
  DefaultComponentKind,
  getComponentDisplayName,
  getDefaultComponentLabel,
  getSubComponents,
  getSuperComponents,
  isCodeComponent,
  isContextCodeComponent,
  isPlumeComponent,
  isReusableComponent,
  isShownHostLessCodeComponent,
  sortComponentsByName,
} from "@/wab/components";
import {
  DEVFLAGS,
  HostLessComponentInfo,
  HostLessPackageInfo,
  InsertableTemplatesComponent,
  InsertableTemplatesItem,
  Installable,
} from "@/wab/devflags";
import { codeLit } from "@/wab/exprs";
import { Rect } from "@/wab/geom";
import { ImageAssetType } from "@/wab/image-asset-type";
import { isIcon } from "@/wab/image-assets";
import { syncGlobalContexts } from "@/wab/project-deps";
import { usedHostLessPkgs } from "@/wab/shared/cached-selectors";
import {
  appendCodeComponentMetaToModel,
  isPlainObjectPropType,
  syncPlumeComponent,
} from "@/wab/shared/code-components/code-components";
import { isRenderableType } from "@/wab/shared/core/model-util";
import { isTagListContainer } from "@/wab/shared/core/rich-text-util";
import { CSSProperties } from "@/wab/shared/element-repr/element-repr-v2";
import {
  InsertableTemplateArenaExtraInfo,
  InsertableTemplateComponentExtraInfo,
  InsertableTemplateExtraInfo,
} from "@/wab/shared/insertable-templates/types";
import { FRAMES_CAP } from "@/wab/shared/Labels";
import {
  canAddChildren,
  canAddSiblings,
  getSlotLikeType,
} from "@/wab/shared/parenting";
import { getParentOrSlotSelection } from "@/wab/shared/SlotUtils";
import { allComponents, isHostLessPackage } from "@/wab/sites";
import { SlotSelection } from "@/wab/slots";
import { unbundleProjectDependency } from "@/wab/tagged-unbundle";
import * as Tpls from "@/wab/tpls";
import { notification } from "antd";
import { UseComboboxGetItemPropsOptions } from "downshift";
import L, { mapValues, uniqBy } from "lodash";
import { observer } from "mobx-react";
import * as React from "react";
import { FocusScope } from "react-aria";
import AutoSizer from "react-virtualized-auto-sizer";
import { areEqual, VariableSizeList } from "react-window";
import {
  cloneInsertableTemplate,
  cloneInsertableTemplateArena,
  cloneInsertableTemplateComponent,
} from "src/wab/shared/insertable-templates";
import { getPlumeEditorPlugin } from "src/wab/shared/plume/plume-registry";
import { getBaseVariant } from "src/wab/shared/Variants";

// The key that defines the recent items OmnibarGroup
const RECENT_GROUP_KEY = "adddrawer-recent";
const shouldShowPreview = (group: AddItemGroup, item: AddItem): boolean => {
  // We should only show the preview image in AddDrawer under these conditions
  return (
    item.type === "frame" &&
    !!item.addDrawerPreviewImage &&
    group.key !== RECENT_GROUP_KEY
  );
};

export const AddDrawer = observer(function AddDrawer(props: {
  studioCtx: StudioCtx;
  onClose: () => void;
}) {
  const { studioCtx, onClose } = props;
  const [isDragging, setDragging] = React.useState(false);
  const lastUsedItemsRef = React.useRef<AddItem[]>([]);

  const onInsertedItem = (item: AddItem) => {
    lastUsedItemsRef.current.unshift(item);
    lastUsedItemsRef.current = L.uniqBy(lastUsedItemsRef.current, (x) => x.key);
    if (lastUsedItemsRef.current.length > 3) {
      lastUsedItemsRef.current.length = 3;
    }
    onClose();
  };

  if (!studioCtx.showAddDrawer() && !isDragging) {
    return null;
  }

  return (
    <FocusScope contain>
      <AddDrawerContent
        studioCtx={studioCtx}
        onInsertedItem={onInsertedItem}
        onDragStart={() => {
          setDragging(true);
          onClose();
        }}
        onDragEnd={() => setDragging(false)}
        lastUsedItems={[...lastUsedItemsRef.current]}
      />
    </FocusScope>
  );
});

const AddDrawerContent = observer(function AddDrawerContent(props: {
  studioCtx: StudioCtx;
  onDragStart: () => void;
  onDragEnd: () => void;
  onInsertedItem: (item: AddItem) => void;
  lastUsedItems: AddItem[];
}) {
  const { studioCtx, onDragStart, onDragEnd, onInsertedItem, lastUsedItems } =
    props;
  const inputRef = React.useRef<TextboxRef>(null);
  const contentRef = React.useRef<HTMLElement>(null);
  const listRef = React.useRef<VariableSizeList>(null);
  const focusManager = useFocusManager();

  const vc = studioCtx.focusedViewCtx();

  const buildItems = React.useCallback(
    (query: string) => {
      const matcher = new Matcher(query, { matchMiddleOfWord: true });
      const groupedItems = buildAddItemGroups({
        studioCtx: studioCtx,
        matcher: matcher,
        includeFrames: isKnownArena(studioCtx.currentArena),
        lastUsedItems: lastUsedItems,
      });
      // We keep track of two parallel lists of items:
      // 1. `virtualItems` -- a list of items that reflect the structure of the virtualized VariableSizeList.
      //    This list contains items that correspond to the actual AddItems, as well as items
      //    for group header and group separators.
      // 2. `items` -- a flat list of AddItems.  This is the list that is managed by Downshift.
      // When we see "index", we need to be careful about which index we mean!

      let itemIndex = 0;
      const virtualItems: VirtualItem[] = groupedItems.flatMap(
        (group, index) => [
          { type: "header", group } as const,
          ...group.items.map(
            (item) =>
              ({ type: "item", item, group, itemIndex: itemIndex++ } as const)
          ),

          ...(index < groupedItems.length - 1
            ? [{ type: "separator" } as const]
            : []),
        ]
      );

      const items = groupedItems.flatMap((group) => group.items);

      return { virtualItems, items };
    },
    [studioCtx, lastUsedItems]
  );

  const {
    virtualItems,
    getInputProps,
    getItemProps,
    getComboboxProps,
    getMenuProps,
    query,
    highlightedItemIndex,
    setHighlightedItemIndex,
  } = useVirtualCombobox({
    listRef,
    buildItems,
    onSelect: spawnWrapper(async (item) => {
      await onInsert(item);
    }),
    itemToString: (item) => item?.key ?? "",
    alwaysHighlight: true,
  });

  const matcher = new Matcher(query, { matchMiddleOfWord: true });

  const validTplLocs = vc
    ? getValidInsertLocs(vc, getFocusedInsertAnchor(vc))
    : undefined;

  const onInserted = (item: AddItem) => {
    onInsertedItem(item);
  };

  const onInsert = async (item: AddItem) => {
    onInserted(item);
    if (isTplAddItem(item)) {
      const component = item.component;
      if (
        component &&
        isCodeComponent(component) &&
        getComponentPresets(studioCtx, component).length > 0
      ) {
        studioCtx.showPresetsModal(component);
      } else {
        await studioCtx.tryInsertTplItem(item);
      }
    } else if (item.type === "frame") {
      await studioCtx.changeUnsafe(() => {
        item.onInsert(studioCtx);
      });
    }
  };
  return (
    <AddDrawerContext.Provider
      value={{
        studioCtx,
        onDragStart,
        onDragEnd,
        matcher,
        onInserted,
        getItemProps,
        highlightedItemIndex,
        validTplLocs,
      }}
    >
      <PlasmicAddDrawer
        className="add-drawer"
        root={{
          props: {
            onKeyDown: (e) => {
              if (e.key === "ArrowDown") {
                if (highlightedItemIndex >= 0) {
                  setHighlightedItemIndex(highlightedItemIndex + 1);
                } else {
                  setHighlightedItemIndex(0);
                }
                inputRef.current && inputRef.current.focus();
              } else if (e.key === "ArrowUp") {
                if (highlightedItemIndex >= 0) {
                  setHighlightedItemIndex(highlightedItemIndex - 1);
                } else {
                  setHighlightedItemIndex(0);
                }
                inputRef.current && inputRef.current.focus();
              } else if (e.key === "ArrowRight") {
                focusManager.focusNext({ wrap: true });
              } else if (e.key === "ArrowLeft") {
                focusManager.focusPrevious({ wrap: true });
              } else if (e.key.length === 1) {
                inputRef.current && inputRef.current.focus();
              }
            },
            "data-test-id": "add-drawer",
          },
        }}
        leftSearchPanel={{
          searchboxProps: {
            ...getInputProps({
              placeholder: "What would you like to insert?",
              autoFocus: true,
              refKey: "ref",
              onKeyDown: spawnWrapper(async (e) => {
                if (e.key === "Escape" && query.trim().length === 0) {
                  await studioCtx.changeUnsafe(() => {
                    studioCtx.setShowAddDrawer(false);
                    studioCtx.hideOmnibar();
                  });
                }
              }),
              ref: inputRef as any,
            }),
          },
          wrapperProps: getComboboxProps(),
        }}
        content={{
          props: {
            ...getMenuProps({
              "aria-label": "Insert",
              ref: contentRef,
            }),

            style: {
              height: 400,
            },

            // Make the drawer focusable (but not in the tab order), so that
            // clicking on an option here will not blurWithin, closing the
            // popup.
            tabIndex: -1,
            className: "add-drawer__content no-select",
            children: (
              <AutoSizer disableWidth defaultHeight={400}>
                {({ height }) => {
                  return (
                    <VariableSizeList
                      ref={listRef}
                      itemData={virtualItems}
                      itemCount={virtualItems.length}
                      estimatedItemSize={32}
                      height={height}
                      width="100%"
                      overscanCount={2}
                      itemSize={(index) => {
                        const virtualItem = virtualItems[index];
                        if (!virtualItem) {
                          return 0;
                        } else if (virtualItem.type === "header") {
                          return 44;
                        } else if (
                          virtualItem.type === "item" &&
                          shouldShowPreview(virtualItem.group, virtualItem.item)
                        ) {
                          return 96;
                        } else if (virtualItem.type === "item") {
                          return 32;
                        } else {
                          // separator
                          return 9;
                        }
                      }}
                    >
                      {Row}
                    </VariableSizeList>
                  );
                }}
              </AutoSizer>
            ),
          },
        }}
      />
    </AddDrawerContext.Provider>
  );
});

export function createAddTplImage(asset: ImageAsset): AddTplItem {
  return {
    type: AddItemType.tpl as const,
    key: `tpl-image-${asset.uuid}`,
    label: asset.name,
    canWrap: false,
    icon: (
      <ImagePreview
        style={{ width: 24, height: 24 }}
        uri={ensure(asset.dataUri, "asset should have dataUri")}
      />
    ),
    factory: (vc: ViewCtx) => vc.variantTplMgr().mkTplImage({ asset: asset }),
  };
}

type CreateAddInstallableExtraInfo = InsertableTemplateExtraInfo & {
  component?: Component;
  arena?: Arena;
};

export function createAddInstallable(meta: Installable): AddInstallableItem {
  return {
    type: AddItemType.installable as const,
    key: `installable-${meta.name}` as const,
    label: meta.name,
    isPackage: true,
    isNew: meta.isNew,
    previewImage: meta.imageUrl
      ? getAddTplItemPreviewImage(meta.imageUrl, "center center")
      : undefined,
    icon: GROUP_ICON,
    asyncExtraInfo: async (
      sc
    ): Promise<CreateAddInstallableExtraInfo | undefined> => {
      const { projectId, groupName } = meta;
      return sc.app.withSpinner(
        (async () => {
          await sc.projectDependencyManager.fetchInsertableTemplate(
            meta.projectId
          );
          const site =
            sc.projectDependencyManager.insertableSites[meta.projectId];
          const missingDeps = site.projectDependencies
            .filter(
              (d) =>
                !sc.site.projectDependencies.find((td) => d.pkgId === td.pkgId)
            )
            .map((d) => d.projectId);

          for (const id of missingDeps) {
            await sc.projectDependencyManager.addByProjectId(id);
          }
          const { screenVariant } = await getScreenVariantToInsertableTemplate(
            sc
          );

          const commonInfo: InsertableTemplateExtraInfo = {
            site,
            screenVariant,
            ...(await getHostLessDependenciesToInsertableTemplate(sc, site)),
            projectId,
            groupName,
            resolution: {
              token: "reuse-by-name",
              component: "reuse",
            },
          };
          if (meta.entryPoint.type === "arena") {
            const arena = site.arenas.find(
              (c) => c.name === meta.entryPoint.name
            );

            if (!arena) return undefined;

            return {
              ...commonInfo,
              arena,
            };
          }

          const component = site.components.find(
            (c) => c.name === meta.entryPoint.name
          );

          if (!component) return undefined;

          return {
            ...commonInfo,
            component,
          };
        })()
      );
    },
    factory: (sc: StudioCtx, extraInfo: CreateAddInstallableExtraInfo) => {
      if (!extraInfo) return undefined;
      if (meta.entryPoint.type === "arena") {
        if (!extraInfo.arena) return undefined;
        const { arena, seenFonts } = cloneInsertableTemplateArena(
          sc.site,
          extraInfo as InsertableTemplateArenaExtraInfo,
          sc.projectDependencyManager.plumeSite
        );
        postInsertableTemplate(sc, seenFonts);
        return arena;
      }

      if (!extraInfo.component) return undefined;

      const { component, seenFonts } = cloneInsertableTemplateComponent(
        sc.site,
        extraInfo as InsertableTemplateComponentExtraInfo,
        sc.projectDependencyManager.plumeSite
      );
      postInsertableTemplate(sc, seenFonts);

      return component;
    },
  };
}

export function createAddTplComponent(component: Component): AddTplItem {
  return {
    type: AddItemType.tpl as const,
    key: `tpl-component-${component.uuid}`,
    label: getComponentDisplayName(component),
    systemName: component.name,
    canWrap: false,
    icon: isPlumeComponent(component) ? (
      <PlumyIcon>{COMPONENT_ICON}</PlumyIcon>
    ) : (
      COMPONENT_ICON
    ),
    factory: (vc: ViewCtx) => {
      const tpl = vc.variantTplMgr().mkTplComponentWithDefaults(component);
      const plugin = getPlumeEditorPlugin(tpl.component);
      if (plugin) {
        plugin.onComponentInserted?.(component, tpl);
      }
      return tpl;
    },
    component,
  };
}

function getAddTplItemPreviewImage(
  url: string,
  objectPosition: CSSProperties["objectPosition"]
) {
  return (
    <img
      className={cx("no-pointer-events", sty.previewImage)}
      style={{
        objectPosition,
      }}
      role="img"
      src={url}
    />
  );
}

export function createAddTplCodeComponent(
  component: CodeComponent,
  showImages: boolean
): AddTplItem {
  const thumbUrl = component.codeComponentMeta.thumbnailUrl;
  return {
    ...createAddTplComponent(component),
    [showImages ? "previewImage" : "icon"]: thumbUrl ? (
      getAddTplItemPreviewImage(thumbUrl, "center center")
    ) : (
      <Icon icon={ComponentIcon} size="100%" />
    ),
    // If we are showing images, we want to show the compact version of the item
    isCompact: showImages,
  };
}

export function createAddTplCodeComponents(
  components: CodeComponent[]
): AddTplItem[] {
  const uniqComponents = uniqBy(components, (c) => c.uuid);
  const shouldShowImages = uniqComponents.some(
    (c) => c.codeComponentMeta.thumbnailUrl
  );
  return sortComponentsByName(uniqComponents)
    .filter(isCodeComponent)
    .map((component) => {
      return createAddTplCodeComponent(component, shouldShowImages);
    });
}

export function createAddComponentPreset(
  studioCtx: StudioCtx,
  component: CodeComponent,
  preset: Preset
): AddTplItem {
  return {
    type: AddItemType.tpl as const,
    key: `preset-${component.uuid}-${preset.name}`,
    label: preset.name,
    icon: COMPONENT_ICON,
    factory: (vc: ViewCtx) => {
      const tpl = Tpls.clone(preset.tpl);
      const targetVariants = [vc.variantTplMgr().getBaseVariantForNewNode()];
      [...Tpls.findVariantSettingsUnderTpl(tpl)].forEach(([vs]) => {
        replaceAll(vs.variants, targetVariants);
      });
      return tpl;
    },
    previewImage: preset.screenshot ? (
      <img className={"preset-screenshot"} src={preset.screenshot} />
    ) : undefined,
  };
}

export function createAddInsertableTemplate(
  meta: InsertableTemplatesItem
): AddTplItem<InsertableTemplateComponentExtraInfo> {
  return {
    type: AddItemType.tpl as const,
    key: `insertable-template-item-${meta.projectId}-${meta.componentName}`,
    label: meta.componentName,
    canWrap: false,
    icon: COMBINATION_ICON,
    previewImage: meta.imageUrl
      ? getAddTplItemPreviewImage(meta.imageUrl, "center top")
      : null,
    factory: (
      vc: ViewCtx,
      extraInfo: InsertableTemplateComponentExtraInfo,
      _drawnRect?: Rect
    ) => {
      trackEvent("Insertable template", {
        insertableName: extraInfo.component.name,
      });
      const targetComponent = vc.currentComponent();
      const { tpl, seenFonts } = cloneInsertableTemplate(
        vc.site,
        extraInfo,
        getBaseVariant(targetComponent),
        vc.studioCtx.projectDependencyManager.plumeSite,
        targetComponent
      );
      postInsertableTemplate(vc.studioCtx, seenFonts);
      return tpl;
    },
    asyncExtraInfo: async (
      sc
    ): Promise<InsertableTemplateComponentExtraInfo> => {
      const { screenVariant } = await getScreenVariantToInsertableTemplate(sc);
      return sc.app.withSpinner(
        (async () => {
          const info = await buildInsertableExtraInfo(
            sc,
            meta.projectId,
            meta.componentName,
            screenVariant
          );
          assert(info, () => `Cannot find template for ${meta.componentName}`);
          return info;
        })()
      );
    },
  };
}

type CreateAddTemplateComponentExtraInfo =
  | { type: "existing"; component: Component }
  | ({ type: "clone" } & InsertableTemplateComponentExtraInfo);

export function createAddTemplateComponent(
  meta: InsertableTemplatesComponent,
  defaultKind?: string
): AddTplItem<CreateAddTemplateComponentExtraInfo> {
  return {
    type: AddItemType.tpl as const,
    key: `insertable-template-component-${meta.projectId}-${meta.componentName}`,
    label: meta.displayName ?? meta.componentName,
    canWrap: false,
    icon: COMBINATION_ICON,
    previewImage: meta.imageUrl
      ? getAddTplItemPreviewImage(meta.imageUrl, "center top")
      : null,
    factory: (
      vc: ViewCtx,
      extraInfo: CreateAddTemplateComponentExtraInfo,
      _drawnRect?: Rect
    ) => {
      if (extraInfo.type === "existing") {
        return createAddTplComponent(extraInfo.component).factory(
          vc,
          extraInfo,
          _drawnRect
        );
      }
      trackEvent("Insertable template component", {
        insertableName: `${meta.projectId}-${meta.componentName}`,
      });
      const { component: comp, seenFonts } = cloneInsertableTemplateComponent(
        vc.site,
        extraInfo,
        vc.studioCtx.projectDependencyManager.plumeSite
      );
      if (defaultKind) {
        setTimeout(() => {
          void vc.studioCtx.change(({ success }) => {
            // ASK: If I try to do this, the Studio hangs (no longer responds to click events) and needs to be restarted. Why?
            // I had to put it inside a settimeout and then wrap it in a .change to make it work.
            vc.studioCtx
              .tplMgr()
              .addComponentToDefaultComponents(comp, defaultKind);
            return success();
          });
        }, 1000);
      }
      postInsertableTemplate(vc.studioCtx, seenFonts);
      return createAddTplComponent(comp).factory(vc, extraInfo, _drawnRect);
    },
    asyncExtraInfo: async (
      sc
    ): Promise<CreateAddTemplateComponentExtraInfo> => {
      const existing = allComponents(sc.site, {
        includeDeps: "all",
      }).find((comp) => comp.templateInfo?.name === meta.templateName);
      if (existing) {
        return {
          type: "existing",
          component: existing,
        };
      }
      const { screenVariant } = await getScreenVariantToInsertableTemplate(sc);
      return sc.app.withSpinner(
        (async () => {
          const info = await buildInsertableExtraInfo(
            sc,
            meta.projectId,
            meta.componentName,
            screenVariant
          );
          assert(
            info,
            () => `Template component ${meta.componentName} not found`
          );
          return {
            type: "clone",
            ...info,
          };
        })()
      );
    },
  };
}

export type HostLessComponentExtraInfo = {
  dep: ProjectDependency[];
  component: Component | undefined;
  args?: Record<string, Expr>;
};

export function createAddHostLessComponent(
  meta: HostLessComponentInfo,
  projectIds: string[]
): AddTplItem<HostLessComponentExtraInfo | false> {
  return {
    type: AddItemType.tpl as const,
    key: `hostless-component-${meta.componentName}`,
    label: meta.displayName,
    canWrap: false,
    icon: COMBINATION_ICON,
    gray: meta.gray,
    previewImage: meta.imageUrl ? (
      getAddTplItemPreviewImage(meta.imageUrl, "center center")
    ) : meta.videoUrl ? (
      <video
        className={"no-pointer-events"}
        src={meta.videoUrl}
        autoPlay
        loop
        muted
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
          objectPosition: "center center",
          objectFit: "contain",
          minWidth: 0,
          minHeight: 0,
          pointerEvents: "none",
        }}
      />
    ) : undefined,
    factory: (vc, ctx) => {
      if (!ctx) {
        return undefined;
      }
      const { component, args } = ctx;
      if (
        checkAndNotifyUnsupportedHostVersion(meta.requiredHostVersion) ||
        !component
      ) {
        return undefined;
      }
      return vc.variantTplMgr().mkTplComponentX({
        component,
        args,
      });
    },
    asyncExtraInfo: async (sc, opts) => {
      return await sc.app.withSpinner(
        (async () => {
          const { deps } = await installHostlessPkgs(sc, projectIds);
          if (!deps) {
            return false;
          }
          const component = ensure(
            deps
              .flatMap((dep2) => dep2.site.components)
              .find((c) => c.name === meta.componentName.split("/")[0]),
            "comp should exist"
          );
          const ccMeta = component && sc.getCodeComponentMeta(component);
          const args = meta.args
            ? mapValues(meta.args, (v) => codeLit(v))
            : undefined;
          if (
            opts?.isDragging ||
            !ccMeta ||
            !hackyCast(ccMeta).preInsertion ||
            !sc.appCtx.appConfig.schemaDrivenForms
          ) {
            return { dep: deps, component, args };
          }
          const argsPre = await getPreInsertionProps(sc, component);
          return args ? { dep: deps, component, args: argsPre } : false;
        })()
      );
    },
  };
}

export function createInstallOnlyPackage(
  meta: HostLessComponentInfo,
  packageMeta: HostLessPackageInfo
): AddFakeItem<HostLessComponentExtraInfo | false> {
  const projectIds = ensureArray(packageMeta.projectId);
  return {
    type: AddItemType.fake as const,
    key: `hostless-component-${meta.componentName}`,
    label: meta.displayName,
    icon: COMBINATION_ICON,
    gray: meta.gray,
    isPackage: true,
    hostLessPackageInfo: packageMeta,
    hostLessComponentInfo: meta,
    previewImage: meta.imageUrl ? (
      getAddTplItemPreviewImage(meta.imageUrl, "center center")
    ) : meta.videoUrl ? (
      <video
        src={meta.videoUrl}
        autoPlay
        loop
        muted
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
          objectPosition: "center center",
          objectFit: "contain",
          minWidth: 0,
          minHeight: 0,
          pointerEvents: "none",
        }}
      />
    ) : undefined,
    factory: (sc, ctx) => {
      if (!ctx || checkAndNotifyUnsupportedHostVersion()) {
        return false;
      }
      if (packageMeta.syntheticPackage) {
        sc.shownSyntheticSections.set(packageMeta.codeName, true);
      }
      return true;
    },
    asyncExtraInfo: async (sc) =>
      sc.app.withSpinner(
        (async () => {
          const { deps } = await installHostlessPkgs(sc, projectIds);
          if (!deps) {
            return false;
          }
          return { dep: deps, component: undefined };
        })()
      ),
  };
}

export function createFakeHostLessComponent(
  meta: HostLessComponentInfo,
  projectIds: string[]
): AddFakeItem<HostLessComponentExtraInfo | false> {
  return {
    type: AddItemType.fake as const,
    key: `hostless-component-${meta.componentName}`,
    label: meta.displayName,
    icon: COMBINATION_ICON,
    gray: meta.gray,
    monospaced: meta.monospaced,
    description: meta.description,
    previewImage: meta.imageUrl ? (
      getAddTplItemPreviewImage(meta.imageUrl, "center center")
    ) : meta.videoUrl ? (
      <video
        className={"no-pointer-events"}
        src={meta.videoUrl}
        autoPlay
        loop
        muted
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
          objectPosition: "center center",
          objectFit: "contain",
          minWidth: 0,
          minHeight: 0,
          pointerEvents: "none",
        }}
      />
    ) : undefined,
    factory: (sc, ctx) => {
      if (
        !ctx ||
        checkAndNotifyUnsupportedHostVersion(meta.requiredHostVersion)
      ) {
        return false;
      }
      ctx.dep.forEach((dep) => {
        const isCodeLibrary =
          dep.site.components.length === 0 && dep.site.codeLibraries.length > 0;
        if (!isCodeLibrary) return;
        dep.site.codeLibraries.forEach((lib) => {
          if (!dep.site.hostLessPackageInfo?.name) return;
          notifyCodeLibraryInsertion(
            dep.site.hostLessPackageInfo.name,
            lib.jsIdentifier,
            typeof sc
              .getRegisteredLibraries()
              .find((r) => r.meta.jsIdentifier === lib.jsIdentifier)?.lib
          );
        });
      });
      return true;
    },
    asyncExtraInfo: async (sc) => {
      return sc.app.withSpinner(
        (async () => {
          const { deps } = await installHostlessPkgs(sc, projectIds);
          if (!deps) {
            return false;
          }
          return { dep: deps, component: undefined };
        })()
      );
    },
  };
}

async function installHostlessPkgs(sc: StudioCtx, projectIds: string[]) {
  const existingDep = sc.site.projectDependencies.filter((dep) =>
    projectIds.includes(dep.projectId)
  );
  if (existingDep && existingDep.length === projectIds.length) {
    return {
      deps: existingDep,
    };
  }
  const projectDependencies = existingDep;
  const remainingProjectIds = projectIds.filter(
    (id) => !existingDep.some((dep) => dep.projectId === id)
  );
  for (const projectId of remainingProjectIds) {
    const { pkg: maybePkg } = await sc.appCtx.api.getPkgByProjectId(projectId);
    const pkg = ensure(maybePkg, "pkg must exist");
    const { pkg: latest, depPkgs } = await sc.appCtx.api.getPkgVersion(pkg.id);
    const { projectDependency } = unbundleProjectDependency(
      sc.bundler(),
      latest,
      depPkgs
    );
    projectDependencies.push(projectDependency);
  }

  if (checkAndNotifyUnsupportedReactVersion(sc, projectDependencies)) {
    return { deps: undefined };
  }
  await sc.updateCcRegistry([
    ...usedHostLessPkgs(sc.site),
    ...projectDependencies.flatMap((dep) => usedHostLessPkgs(dep.site)),
  ]);

  await sc.change(({ success }) => {
    for (const projectDependency of projectDependencies) {
      if (
        !sc.site.projectDependencies.some(
          (dep) => dep.pkgId === projectDependency.pkgId
        )
      ) {
        sc.site.projectDependencies.push(projectDependency);
        syncGlobalContexts(projectDependency, sc.site);
        sc.projectDependencyManager.syncDirectDeps();
        maybeShowGlobalContextNotification(sc, projectDependency);
      }
    }
    appendCodeComponentMetaToModel(
      sc.site,
      sc.getCodeComponentsAndContextsRegistration()
    );
    return success();
  });
  return { deps: projectDependencies };
}

export function createAddInsertableIcon(icon: ImageAsset): AddTplItem {
  return {
    type: AddItemType.tpl as const,
    key: `insertable-icon-${icon.uuid}`,
    label: icon.name,
    canWrap: false,
    icon: (
      <ImagePreview
        style={{ width: 24, height: 24 }}
        uri={ensure(icon.dataUri, "icon should have dataUri")}
      />
    ),
    factory: (vc: ViewCtx) => {
      trackEvent("Insertable icon", {
        type: icon.type,
        name: icon.name,
      });
      const clonedIcon = vc.tplMgr().addImageAsset({
        name: icon.name,
        type: icon.type as ImageAssetType,
        dataUri: icon.dataUri ?? undefined,
        width: icon.width ?? undefined,
        height: icon.height ?? undefined,
        aspectRatio: icon.aspectRatio ?? undefined,
      });
      return vc.variantTplMgr().mkTplImage({ asset: clonedIcon });
    },
  };
}

interface AddDrawerContextValue {
  studioCtx: StudioCtx;
  onDragStart: () => void;
  onDragEnd: () => void;
  matcher: Matcher;
  onInserted: (item: AddItem) => void;
  getItemProps: (options: UseComboboxGetItemPropsOptions<AddItem>) => any;
  highlightedItemIndex: number;
  validTplLocs: Set<InsertRelLoc> | undefined;
}

const AddDrawerContext = React.createContext<AddDrawerContextValue | undefined>(
  undefined
);

type VirtualItem =
  | {
      type: "header";
      group: AddItemGroup;
      item?: never;
    }
  | {
      type: "item";
      item: AddItem;
      group: AddItemGroup;
      itemIndex: number;
    }
  | {
      type: "separator";
      item?: never;
    };

const Row = React.memo(function Row(props: {
  data: VirtualItem[];
  index: number;
  style: React.CSSProperties;
}) {
  const { style } = props;
  const virtualItem = props.data[props.index];
  const context = ensure(
    React.useContext(AddDrawerContext),
    "AddDrawerContext should exist"
  );

  if (virtualItem.type === "header") {
    return (
      <ListSectionHeader style={style}>
        {virtualItem.group.label}
      </ListSectionHeader>
    );
  } else if (virtualItem.type === "item") {
    const { item, group, itemIndex } = virtualItem;
    const {
      studioCtx,
      onDragStart,
      onDragEnd,
      matcher,
      onInserted,
      getItemProps,
      validTplLocs,
      highlightedItemIndex,
    } = context;

    const indent =
      isTplAddItem(item) && item.component
        ? getSuperComponents(item.component).length
        : 0;

    return (
      <li
        {...getItemProps({ item, index: itemIndex })}
        aria-label={item.label}
        role="option"
        className={item.type === "tpl" ? "grabbable" : ""}
        style={style}
      >
        <MaybeWrap
          cond={item.type === "tpl"}
          wrapper={(child) => (
            <DraggableInsertable
              key={item.key}
              sc={studioCtx}
              spec={item as AddTplItem}
              onDragStart={onDragStart}
              onDragEnd={onDragEnd}
            >
              {child}
            </DraggableInsertable>
          )}
        >
          <AddDrawerItem
            key={item.key}
            studioCtx={studioCtx}
            item={item}
            matcher={matcher}
            isHighlighted={highlightedItemIndex === itemIndex}
            validTplLocs={validTplLocs}
            onInserted={() => {
              onInserted(item);
            }}
            indent={indent}
            // Hide the preview image when recent
            showPreviewImage={shouldShowPreview(group, item)}
          />
        </MaybeWrap>
      </li>
    );
  } else {
    return (
      <ListSectionSeparator
        style={{
          ...style,
          paddingTop: 4,
        }}
      />
    );
  }
},
areEqual);

export function buildAddItemGroups({
  studioCtx,
  includeFrames = true,
  matcher,
  lastUsedItems = [],
  filterToTarget,
  insertLoc,
}: {
  includeFrames?: boolean;
  studioCtx: StudioCtx;
  matcher: Matcher;
  lastUsedItems?: AddItem[];
  filterToTarget?: boolean;
  insertLoc?: InsertRelLoc;
}): AddItemGroup[] {
  const insertableTemplatesMeta =
    studioCtx.appCtx.appConfig.insertableTemplates ??
    DEVFLAGS.insertableTemplates;
  const hostLessComponentsMeta =
    studioCtx.appCtx.appConfig.hostLessComponents ??
    DEVFLAGS.hostLessComponents;
  const contentEditorMode = studioCtx.contentEditorMode;
  const contentEditorConfig = studioCtx.getCurrentUiConfig();
  const groupedItems: AddItemGroup[] = filterFalsy([
    includeFrames &&
      !contentEditorMode && {
        key: "frames",
        label: FRAMES_CAP,
        items: [
          INSERTABLES_MAP.pageFrame,
          INSERTABLES_MAP.componentFrame,
          INSERTABLES_MAP.screenFrame,
        ],
      },

    (!contentEditorMode || contentEditorConfig.canInsertBasics) && {
      key: "basics",
      label: "Basics",
      items: [
        INSERTABLES_MAP.text,
        INSERTABLES_MAP.image,
        ...makePlumeInsertables(studioCtx).filter(
          (item) => item.label === "Button"
        ),
        INSERTABLES_MAP.vstack,
        INSERTABLES_MAP.columns,
        INSERTABLES_MAP.icon,
      ],
    },

    // Insertable Templates
    !contentEditorMode &&
      !!insertableTemplatesMeta && {
        key: "insertable-templates",
        label: "Template blocks",
        items: [INSERTABLES_MAP.openInsertModal],
      },

    !contentEditorMode &&
      !!hostLessComponentsMeta && {
        key: "hostless-components",
        label: "Component Packages",
        items: [INSERTABLES_MAP.openHostLessModal],
      },

    !contentEditorMode && {
      key: "more-layout",
      label: "More layout",
      items: [
        INSERTABLES_MAP.vstack,
        INSERTABLES_MAP.columns,
        INSERTABLES_MAP.hstack,
        INSERTABLES_MAP.grid,
        INSERTABLES_MAP.box,
      ],
    },

    !contentEditorMode && {
      key: "typography",
      label: "Typography",
      items: [
        INSERTABLES_MAP.text,
        INSERTABLES_MAP.heading,
        INSERTABLES_MAP.link,
      ],
    },

    !contentEditorMode && {
      key: "links",
      label: "Links",
      items: [INSERTABLES_MAP.link, INSERTABLES_MAP.linkContainer],
    },

    // Plume components
    !contentEditorMode && {
      key: "plume-templates",
      label: "Interactive Components",
      items: makePlumeInsertables(studioCtx),
    },

    {
      key: "components",
      label: "Project Components",
      items: sortComponentsByName(
        studioCtx.site.components.filter(
          (c) => isReusableComponent(c) && !isCodeComponent(c)
        )
      ).map((comp) => createAddTplComponent(comp)),
    },

    {
      key: "code-components",
      label: "Code Components",
      items: sortComponentsByName(
        studioCtx.site.components.filter(
          (c) =>
            isReusableComponent(c) &&
            isCodeComponent(c) &&
            !isContextCodeComponent(c)
        )
      ).map((comp) => createAddTplComponent(comp)),
    },

    !contentEditorMode && {
      key: "unstyled",
      label: "Unstyled Elements",
      items: [
        INSERTABLES_MAP.button,
        INSERTABLES_MAP.textbox,
        INSERTABLES_MAP.password,
        INSERTABLES_MAP.textarea,
        INSERTABLES_MAP.ul,
        INSERTABLES_MAP.ol,
        INSERTABLES_MAP.li,
      ],
    },

    !contentEditorMode && {
      key: "icons",
      label: "Icons",
      items: studioCtx.site.imageAssets
        .filter((asset) => isIcon(asset))
        .map((asset) => createAddTplImage(asset)),
    },

    !contentEditorMode && {
      key: "images",
      label: "Images",
      items: studioCtx.site.imageAssets
        .filter(
          (asset) => asset.type === ImageAssetType.Picture && asset.dataUri
        )
        .map((asset) => createAddTplImage(asset)),
    },

    ...studioCtx.site.projectDependencies.map((dep) => ({
      key: dep.pkgId,
      label: `Imported from ${
        isHostLessPackage(dep.site)
          ? `package "${dep.name}"`
          : `"${dep.name}" (${dep.version})`
      }`,
      items: [
        ...sortComponentsByName(
          dep.site.components.filter(
            (c) =>
              isReusableComponent(c) &&
              (!isCodeComponent(c) ||
                isShownHostLessCodeComponent(c, hostLessComponentsMeta)) &&
              !isContextCodeComponent(c)
          )
        ).map((comp) => createAddTplComponent(comp)),
        ...dep.site.imageAssets
          .filter((asset) => asset.dataUri)
          .map((asset) => createAddTplImage(asset)),
      ],
    })),
  ]);

  // We clone all items to avoid having duplicates in groupedItems, because
  // that can cause issues with react-window's lists.
  groupedItems.forEach((group) => {
    group.items = group.items.map((i) => L.clone(i));
  });

  if (matcher.hasQuery()) {
    groupedItems.forEach((group) => {
      const unmatchedItems = new Set(
        group.items.filter((item) => !matcher.matches(item.label))
      );
      const superAndSubCompsOfMatchedComponents = new Set<Component>();
      group.items.forEach((item) => {
        if (
          matcher.matches(item.label) &&
          item.type === AddItemType.tpl &&
          !!item.component
        ) {
          [
            ...getSuperComponents(item.component),
            ...getSubComponents(item.component),
          ].forEach((c) => superAndSubCompsOfMatchedComponents.add(c));
        }
      });
      group.items.forEach((item) => {
        if (
          item.type === AddItemType.tpl &&
          !!item.component &&
          superAndSubCompsOfMatchedComponents.has(item.component)
        ) {
          unmatchedItems.delete(item);
        }
      });
      group.items = group.items.filter((item) => !unmatchedItems.has(item));
    });
  }

  if (filterToTarget) {
    let target: TplNode | SlotSelection | null = null;
    const vc = studioCtx.focusedViewCtx();
    if (vc) {
      target = vc.focusedTplOrSlotSelection();
      if (target) {
        for (const group of groupedItems) {
          group.items = group.items.filter((item) =>
            isInsertable(item, vc, target!, insertLoc)
          );
        }
        lastUsedItems = lastUsedItems.filter((item) =>
          isInsertable(item, vc, target!, insertLoc)
        );
      }
    }
  }

  if (lastUsedItems.length > 0) {
    const validLastUsedItems = lastUsedItems.filter((x) =>
      groupedItems.some((group) => group.items.includes(x))
    );

    if (validLastUsedItems.length > 0) {
      groupedItems.unshift({
        key: RECENT_GROUP_KEY,
        label: "Recently Used...",
        // Note: useCombobox will do shallow comparisons and get confused when there are 2 identical elements
        // For example, highlightedIndex will be set to the first occurrence, leading to a lot of jumping around
        // By just cloning this, we can keep the items distinct
        items: [...validLastUsedItems.map((i) => L.clone(i))],
      });
    }
  }

  return groupedItems.filter((group) => group.items.length > 0);
}

export function isInsertable(
  item: AddItem,
  vc: ViewCtx,
  target: TplNode | SlotSelection,
  insertLoc?: InsertRelLoc
) {
  if (!isTplAddItem(item)) {
    return false;
  }
  insertLoc = insertLoc ?? InsertRelLoc.append;
  if (insertLoc === InsertRelLoc.wrap && !item.canWrap) {
    return false;
  }

  if (!isAsChildRelLoc(insertLoc) && !isAsSiblingRelLoc(insertLoc)) {
    return false;
  }

  if (target instanceof SlotSelection && isAsSiblingRelLoc(insertLoc)) {
    // cannot insert as a sibling to a SlotSelection
    return false;
  }

  if (Tpls.isTplTag(target)) {
    if (
      (isTagListContainer(target.tag) && isAsChildRelLoc(insertLoc)) ||
      (target.tag === "li" && isAsSiblingRelLoc(insertLoc))
    ) {
      // Only list items can be added to "ul" and "ol" containers; and only
      // list items can be siblings of list items.
      return item.key === "li";
    }
  }

  const canAdd =
    (isAsChildRelLoc(insertLoc) && canAddChildren(target)) ||
    (isKnownTplNode(target) &&
      isAsSiblingRelLoc(insertLoc) &&
      canAddSiblings(target));

  if (item.type === "plume") {
    // Don't allow inserting new Plume components into constrained
    // slot.  We exit early this way instead of calling item.factory()
    // because doing so will actually create and attach the component
    // to the Site, even before we've done the insertion!
    return !isTargetConstrainedSlot(target, insertLoc) && canAdd;
  }

  if (item.asyncExtraInfo) {
    // Don't create tentatively throwaway tpl to check if it fits into
    // a constrained slot; instead, never allow them in constrained slots
    // for now.
    return !isTargetConstrainedSlot(target, insertLoc) && canAdd;
  }

  const toInsert = item.factory(vc, undefined);
  if (toInsert == null) {
    return false;
  }

  if (isAsChildRelLoc(insertLoc)) {
    return canAddChildren(target, toInsert);
  }

  if (isKnownTplNode(target) && isAsSiblingRelLoc(insertLoc)) {
    return canAddSiblings(target, toInsert);
  }

  return false;
}

function isTargetConstrainedSlot(
  target: TplNode | SlotSelection,
  insertLoc: AsChildInsertRelLoc | AsSiblingInsertRelLoc
) {
  if (isKnownTplNode(target) && isAsSiblingRelLoc(insertLoc)) {
    const parent = getParentOrSlotSelection(target);
    if (!parent) {
      return false;
    }
    target = parent;
  }
  if (Tpls.isTplSlot(target) || target instanceof SlotSelection) {
    const slotType = getSlotLikeType(target);
    if (isRenderableType(slotType)) {
      // Renderable with constraints set in params
      return slotType.params.length > 0;
    } else {
      // some constraint involved
      return false;
    }
  } else {
    return false;
  }
}

export function makePlumeInsertables(
  studioCtx: StudioCtx,
  filteredKind?: DefaultComponentKind
) {
  const plumeSite = studioCtx.projectDependencyManager.plumeSite;
  if (!plumeSite) {
    return [];
  }
  const plumeComponents = getPlumeComponentTemplates(studioCtx);

  const existingTypes = new Set(
    withoutNils([...studioCtx.site.components.map((c) => c.plumeInfo?.type)])
  );

  const items: AddItem[] = [];
  for (const component of plumeComponents) {
    if (
      !existingTypes.has(component.plumeInfo.type) &&
      (!filteredKind || component.plumeInfo.type === filteredKind)
    ) {
      items.push({
        type: AddItemType.plume,
        key: component.uuid,
        label: getDefaultComponentLabel(component.plumeInfo.type),
        canWrap: false,
        icon: <Icon icon={PlumeMarkIcon} />,
        factory: (vc: ViewCtx, extraInfo) => {
          const isComponentInserted = extraInfo.attachComponent;
          const newComponent = studioCtx
            .tplMgr()
            .clonePlumeComponent(
              plumeSite,
              component.uuid,
              component.name,
              isComponentInserted
            );
          syncPlumeComponent(studioCtx, newComponent).match({
            success: (x) => x,
            failure: (err) => {
              throw err;
            },
          });
          const tpl = vc
            .variantTplMgr()
            .mkTplComponentWithDefaults(newComponent);
          if (isComponentInserted) {
            const plugin = getPlumeEditorPlugin(newComponent);
            plugin?.onComponentInserted?.(vc.component, tpl);
          }
          return tpl;
        },
        asyncExtraInfo: async (_studioCtx, opts) => {
          return { attachComponent: !opts?.isDragging };
        },
        previewImage: getAddTplItemPreviewImage(
          getPlumeImage(component.plumeInfo.type),
          "center center"
        ),
      });
    }
  }
  return items;
}

export function maybeShowGlobalContextNotification(
  studioCtx: StudioCtx,
  projectDependency: ProjectDependency
) {
  const key = "global-context-notification";
  const goToSettings = async () => {
    await studioCtx.change(({ success }) => {
      studioCtx.hideOmnibar();
      studioCtx.switchLeftTab("settings", { highlight: true });
      notification.close(key);
      return success();
    });
  };
  const tryExtractDataSourceProp = (c: Component) => {
    const meta = studioCtx.getHostLessContextsMap().get(c.name);
    if (!meta) {
      return undefined;
    }
    for (const p of c.params) {
      const propType = meta.meta.props[p.variable.name];
      if (
        isPlainObjectPropType(propType) &&
        (propType as any).type === "dataSource"
      ) {
        return [c, p.variable.name] as const;
      }
    }
    return undefined;
  };
  if (projectDependency.site.globalContexts.length > 0) {
    spawn(
      (async () => {
        // Fetch hostless packages
        await studioCtx.updateCcRegistry(usedHostLessPkgs(studioCtx.site));
        for (const globalContext of projectDependency.site.globalContexts) {
          const dataSourceProp = tryExtractDataSourceProp(
            globalContext.component
          );
          if (dataSourceProp) {
            await goToSettings();
            studioCtx.forceOpenProp = dataSourceProp;
            return;
          }
        }
        notification.info({
          message: "Project Settings",
          description: (
            <>
              <p>
                The {projectDependency.name} package can be configured in
                settings.
              </p>
              <a onClick={goToSettings}>Go to settings.</a>
            </>
          ),
          duration: 30,
          key,
        });
      })()
    );
  }
}
