import {
  BusinessType,
  MainGoals,
  ProductType,
  OnlineSales,
  Leads,
  Showcase,
  News,
  Traffic,
  Community,
  Support,
  AdditionalRequirement,
} from "./templateFormEnums";

export type TemplateFormStep = (typeof templateFormSteps)[number];

export type TemplateFormGroups = typeof templateFormGroups;
export type TemplateFormGroupKey = keyof TemplateFormGroups;

export type TemplateFormGroupValues<T extends TemplateFormGroupKey> =
  TemplateFormGroups[T];
export type TemplateFormGroupValueKey<T extends TemplateFormGroupKey> =
  keyof TemplateFormGroupValues<T>;

export const templateFormSteps = [1, 2, 3, 4] as const;

export const templateFormGroups = {
  BusinessType,
  MainGoals,
  ProductType,
  OnlineSales,
  Leads,
  Showcase,
  News,
  Traffic,
  Community,
  Support,
  AdditionalRequirement,
} as const;
