import { U, UU } from "@/wab/client/cli-routes";
import { ZitadelSignInButton } from "@/wab/client/components/auth/ConnectOAuth";
import * as React from "react";
import { Redirect } from "react-router-dom";
import "./AuthForm.sass";
import { FormFeedback, useAuthForm } from "./AuthForm";
import { IntakeFlowForm } from "./IntakeFlowForm";

interface AuthFormLoginProps {
  mode: "sign in" | "sign up";
  onLoggedIn: () => void;
}

export function AuthFormLogin({ mode, onLoggedIn }: AuthFormLoginProps) {
  const {
    nonAuthCtx,
    appCtx,
    oauthFeedback,
    setOauthFeedback,
    setFormFeedback,
    nextPath,
    setSelfInfo,
  } = useAuthForm({
    mode,
    onLoggedIn: (login) => {
      onLoggedIn();
      if (login) {
        appCtx.router.routeTo(nextPath);
      } else {
        appCtx.router.routeTo(
          UU.survey.fill(
            {},
            {
              continueTo: U.emailVerification({}),
            }
          )
        );
      }
    },
  });

  return (
    <IntakeFlowForm>
      {appCtx.selfInfo ? (
        <Redirect to={nextPath} />
      ) : (
        <div className={"LoginForm__Controls"}>
          <p className={"LoginForm__Title"}>Welcome Back!</p>
          <p className={"LoginForm__Description"}>
            <br />
          </p>
          <div className={"LoginForm__Oauth"}>
            <ZitadelSignInButton
              onStart={() => {
                setFormFeedback(undefined);
                setOauthFeedback(undefined);
              }}
              onSuccess={async () => {
                await nonAuthCtx.api.refreshCsrfToken();
                const { user } = await nonAuthCtx.api.getSelfInfo();
                setSelfInfo(user);
              }}
              onFailure={(reason) => {
                if (reason === "UserNotWhitelistedError") {
                  location.href = "https://plasmic.app/intake";
                } else {
                  setOauthFeedback({
                    type: "error",
                    content: "Unexpected error occurred logging in.",
                  });
                }
              }}
              zitadelAuthUrl={U.zitadelAuthLogin({})}
            >
              {mode === "sign in" ? "Sign in" : "Sign up"}
            </ZitadelSignInButton>
            <FormFeedback feedback={oauthFeedback} />
          </div>
        </div>
      )}
    </IntakeFlowForm>
  );
}
