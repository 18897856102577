import {
  TemplateFormGroupKey,
  TemplateFormGroupValueKey,
} from "./templateForm";

export type TemplateFormResolverForm = {
  [GK in TemplateFormGroupKey]?: TemplateFormGroupValueKey<GK>[];
};

export class TemplateFormResolver {
  constructor(
    private readonly form: TemplateFormResolverForm,
    private readonly router: any
  ) {}

  /*
   * Build a choice state.
   */
  buildState<GK extends TemplateFormGroupKey>(
    group: GK,
    config: {
      self: boolean;
      unique?: boolean;
      required?: boolean;
      children?: Partial<{
        [GVK in TemplateFormGroupValueKey<GK>]: boolean;
      }>;
    }
  ) {
    return { group, ...config };
  }

  /*
   * Check if a choice contains a value.
   */
  contains<GK extends TemplateFormGroupKey>(
    group: GK,
    key: TemplateFormGroupValueKey<GK>
  ) {
    return this.form[group]?.includes(key) === true;
  }

  /*
   * Check if a choice is equal to a value.
   */
  isEqual<GK extends TemplateFormGroupKey>(
    group: GK,
    key: TemplateFormGroupValueKey<GK>
  ) {
    return this.form[group]?.length === 1 && this.contains(group, key);
  }

  /*
   * Check if a choice is empty.
   */
  isEmpty<GK extends TemplateFormGroupKey>(group: GK) {
    return this.form[group] === undefined || this.form[group]?.length === 0;
  }
}
