import env from "@beam-australia/react-env";

/**
 * Client-side environements.
 *
 * @description You must bind .env keys prefixed with REACT_APP_ and always use clientEnv in the code to use these environment variables.
 * All those environment variables MUST NOT contains secrets and CAN ONLY expose values that can be PUBLICLY VISIBLE.
 * To implements secrets, you MUST implement them in the secrets.json file, implemented in secrets.ts.
 */
export const clientEnv = {
  githubAppName: env("GITHUB_APP_NAME"),
  githubClientId: env("GITHUB_CLIENT_ID"),
  nodeEnv: process.env.NODE_ENV,
  zitadelAuthority: env("ZITADEL_AUTHORITY"),
  zitadelClientId: env("ZITADEL_CLIENT_ID"),
  zitadelOrgResourceId: env("ZITADEL_ORG_RESOURCE_ID"),
  zitadelPostLogoutRedirectUri: env("ZITADEL_POST_LOGOUT_REDIRECT_URI"),
  zitadelProjectResourceId: env("ZITADEL_PROJECT_RESOURCE_ID"),
  zitadelRedirectUri: env("ZITADEL_REDIRECT_URI"),
};

if (clientEnv.nodeEnv === "development") {
  console.log("clientEnv", clientEnv);
}
