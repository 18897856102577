/*
 * 1
 */
export enum BusinessType {
  Ecommerce,
  Food,
  Hospitality,
  Agency,
  Technology,
  Education,
  Healthcare,
  RealEstate,
  Business,
  Association,
}

/*
 * 2
 */
export enum MainGoals {
  Sell,
  Leads,
  Awarness,
  Showcase,
  News,
  Traffic,
  Community,
  Support,
}

/*
 * 2.1
 */
export enum ProductType {
  Physical,
  Digital,
  Course,
}

/*
 * 4.1
 */
export enum OnlineSales {
  Ecommerce,
  Appointment,
  Delivery,
  Schedule,
  Payment,
  Course,
}

/*
 * 4.2
 */
export enum Leads {
  SimpleForm,
  AdvancedForm,
  Schedule,
  Offer,
}

/*
 * 4.3
 */
export enum Showcase {
  MainProduct,
  Service,
  Team,
  Mission,
  Contact,
  Testimonials,
  Social,
  Services,
  Jobs,
}

/*
 * 4.5
 */
export enum News {
  Social,
  Newsletter,
  News,
  Comments,
  Donations,
}

/*
 * 4.6
 */
export enum Traffic {
  Booking,
  Management,
  Appointment,
  Schedule,
}

/*
 * 4.7
 */
export enum Community {
  Social,
  Feedback,
  Support,
  Advantage,
}

/*
 * 4.8
 */
export enum Support {
  Form,
  Documentation,
  FAQ,
  Chatbot,
  Forum,
}

/*
 * 4.9
 */
export enum AdditionalRequirement {
  Multilanguages,
  Multiwebsites,
  Marketing,
}
