import Logo, { LogoProps } from "@/wab/client/components/Logo";
import * as React from "react";

export type LogoFullProps = LogoProps;

function LogoFull(props: LogoFullProps) {
  return <Logo src={"static/img/plasmic-webagility.svg"} {...props} />;
}

export default LogoFull;
