import {
  TemplateFormGroupValueKey,
  TemplateFormGroupKey,
  TemplateFormStep,
} from "./templateForm";

export const templateFormEnumsTrans = {
  groups: {
    BusinessType: {
      title: "What type of business do you operate?",
      description: "",
      placeholder: "Choose your industry...",
      values: {
        Ecommerce: "Retail and E-commerce.",
        Food: "Restaurant, Food and Beverage.",
        Hospitality: "Hospitality and Tourism.",
        Agency: "Creative Agency and Freelancer.",
        Technology: "Technology and IT Services.",
        Education: "Education and E-Learning.",
        Healthcare: "Healthcare and Wellness.",
        RealEstate: "Real Estate and Property.",
        Business: "Business Services.",
        Association: "Cultural / Association / ONG.",
      },
    },
    MainGoals: {
      title:
        "What are the main goals you would like to achieve with your website?",
      description: "",
      placeholder: "",
      values: {
        Sell: "Sell Products/Service online.",
        Leads: "Generate Leads / Get prospect informations.",
        Awarness: "Raise Awareness about my product/service online.",
        Showcase: "Showcase some of your work/services/articles.",
        News: "Share news about your company/team/product/service.",
        Traffic: "Drive traffic into my physical shop/office.",
        Community: "Foster Community engagement on social media.",
        Support: "Support your clients through public Q&A, chatbot, forum.",
      },
    },
    ProductType: {
      title: "What type of product or service are you willing to sell online?",
      description: "",
      placeholder: "",
      values: {
        Physical: "Physical goods.",
        Digital: "Digital products.",
        Course: "Online Courses / Webinars / Consulting.",
      },
    },
    OnlineSales: {
      title: "Online Sales",
      description: "",
      placeholder: "",
      values: {
        Ecommerce:
          "E-commerce Integration (Stock, Order, Payment, Delivery, ...).",
        Appointment: "Physical/Virtual Appointment scheduling online.",
        Delivery: "Food and Beverage Delivery service integration (i.e. Uber).",
        Schedule: "Prepayment online to schedule/reserve your service.",
        Payment: "Online course enrolment and online payment.",
        Course: "User personal portal for accessing course material.",
      },
    },
    Leads: {
      title: "Lead Generation",
      description: "Check the feature your client looks to get in his website.",
      placeholder: "Check the feature your client looks to get in his website.",
      values: {
        SimpleForm: "Generate lead through simple contact form.",
        AdvancedForm:
          "Ask precise information to your customer through an advanced contact form.",
        Schedule: "Physical/Virtual Appointment scheduling online.",
        Offer:
          "Share automatically an estimated offer/pricing based on visitors' answers to a dynamic form.",
      },
    },
    Showcase: {
      title: "Raise Awareness and showcase some of your work",
      description: "",
      placeholder: "",
      values: {
        MainProduct: "Your main product presentation.",
        Service: "Your service presentation.",
        Team: "Your team presentation.",
        Mission: "Your Mission and Vision Statement.",
        Contact: "Your contact information.",
        Testimonials: "Your clients testimonials.",
        Social:
          "Link to your social network (i.e. Instagram, Facebook, Linkedin).",
        Services:
          "Present a list of your work/product/service that needs to be frequently updated.",
        Jobs: "Post job offer & get job applications from your website.",
      },
    },
    News: {
      title:
        "Share news about your company/team/product/service with new visitors and current clients",
      description: "",
      placeholder: "",
      values: {
        Social:
          "Display your latest social media post automatically in your website.",
        Newsletter: "Newsletters subscription & Management.",
        News: "Present articles or news that you want to be update frequently.",
        Comments:
          "Let visitor react to your articles or news (likes, comments).",
        Donations: "Get Payment by donators and supporters.",
      },
    },
    Traffic: {
      title: "Drive traffic into a physical shop/office/place",
      description: "",
      placeholder: "",
      values: {
        Booking:
          "Online booking or reservation for event (calendar & availability check).",
        Management: "Integration with table management system (i.e. The Fork).",
        Appointment: "Physical/Virtual Appointment scheduling online.",
        Schedule: "Online Prepayment to schedule/reserve your service.",
      },
    },
    Community: {
      title: "Foster your community engagement",
      description: "",
      placeholder: "",
      values: {
        Social:
          "Display your latest social media post automatically in your website.",
        Feedback:
          "Collect feedback from user about specifics topics through dynamic forms.",
        Support:
          "Propose a forum where your community can find support and tips.",
        Advantage:
          "Propose unique advantage to people who drive traffic to your business (i.e. promotion code).",
      },
    },
    Support: {
      title: "Support your clients through public Q&A, chatbot, forum",
      description: "",
      placeholder: "",
      values: {
        Form: "Let visitor ask for support through an online form.",
        Documentation: "Provide Online Documentation and Knowledge Base.",
        FAQ: 'Present "frequent asked questions" (FAQ) so that you visitor can find answers to recurrent questions directly online.',
        Chatbot:
          "Display a ChatBot that can answer to your clients' main questions.",
        Forum:
          "Propose a forum where your community can find support and tips.",
      },
    },
    AdditionalRequirement: {
      title: "Additionnal requirements",
      description: "",
      placeholder: "",
      values: {
        Multilanguages:
          "This website should be available in multiple languages.",
        Multiwebsites:
          "Need of multiple websites (I.e. one per country, or per different clients' segment) manageable from one back office.",
        Marketing:
          "Marketing tools integration to promote your website on Search engine and social media (Google, Instagram, Facebook).",
      },
    },
  },
  steps: {
    1: {
      title: "👋 Let's generate a new project skeleton together !",
      description:
        "Generating a skeleton is the perfect way to capture your clients needs and to align with every stakeholders, from clients to developers. Need more personalisation ? Don't worry, your team is free to make any further customization directly from the generated skeleton.",
    },
    2: {
      title: "",
      description: "",
    },
    3: {
      title: "",
      description: "",
    },
    4: {
      title:
        "For each goals below, select what features you expect to get on your website.",
      description:
        "Important note for this beta: the websites generated at this stage might not include every features.",
    },
  },
  misc: {
    choosen: "Choosen industry:",
    back: "Back",
    next: "Next",
    start: "Generate a project template",
  },
} satisfies {
  groups: {
    [GK in TemplateFormGroupKey]: {
      title: string;
      description: string;
      placeholder: string;
      values: {
        [GVK in TemplateFormGroupValueKey<GK>]: string;
      };
    };
  };
  steps: {
    [S in TemplateFormStep]: {
      title: string;
      description: string;
    };
  };
  misc: {
    [key: string]: string;
  };
};
